export const API_URL = process.env.REACT_APP_API_URL || "";
export const API_KEY = process.env.REACT_APP_API_KEY || "";
export const GRAPH_API_URL = "https://graph.microsoft.com/v1.0";
export const SPECIAL_DISPLAY_ADMIN_GROUPS = process.env.REACT_APP_SPECIAL_DISPLAY_ADMIN_GROUPS
  ? process.env.REACT_APP_SPECIAL_DISPLAY_ADMIN_GROUPS.split(",")
  : [];
export const THEME_ADMIN_GROUPS = process.env.REACT_APP_THEME_ADMIN_GROUPS
  ? process.env.REACT_APP_THEME_ADMIN_GROUPS.split(",")
  : [];
export const ENV = process.env.NODE_ENV === "development" ? "dev" : "";
