import React, { useState } from "react";
import { Box, Typography, Button, TextField, Grid, IconButton } from "@material-ui/core";
import { useForm } from "react-hook-form";
import CloseIcon from "@material-ui/icons/Close";
import { getLatestDisplayDate, SpecialDisplay, SpecialDisplayFormData } from "../../api/specialDisplays";
import { Product, getProduct } from "../../api/products";
import { useAllStores } from "../../hooks/useAllStores";
import { useThemes } from "../../hooks/useThemes";
import { useSavedTheme } from "../../hooks/useSavedTheme";
import { StoreSelect } from "../filter/StoreSelect";
import { ThemeSelect } from "../filter/ThemeSelect";
import { SpecialDisplayForm } from "./SpecialDisplayForm";
import { useSavedStore } from "../../hooks/useSavedStore";
import { useSavedYear } from "../../hooks/useSavedYear";
import { useSavedPeriod } from "../../hooks/useSavedPeriod";
import { YearSelect } from "../filter/YearSelect";
import { PeriodSelect } from "../filter/PeriodSelect";
import { useThemePeriods } from "../../hooks/useThemePeriods";
import { toDate } from "date-fns-tz";
import { TooFrequentModal } from "./TooFrequentModal";

interface FindProductForm {
  productId: string;
}

interface Props {
  onSave: (values: SpecialDisplayFormData) => void;
  onCancel: () => void;
}

export const CreateSpecialDisplayDrawer = ({ onSave, onCancel }: Props): JSX.Element => {
  const { handleSubmit: handleProductFindSubmit, register: registerProductFind } = useForm<FindProductForm>();
  const [product, setProduct] = useState<Product>();

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => {
    setModalOpen(false);
    setLatestSpecialDisplay(undefined);
  };
  const [latestSpecialDisplay, setLatestSpecialDisplay] = useState<SpecialDisplay>();

  const [error, setError] = useState("");
  const { year } = useSavedYear();
  const { stores } = useAllStores();
  const { storeId } = useSavedStore();
  const [selectedStoreId, setSelectedStoreId] = useState(storeId);
  const selectedStore = stores.find((s) => s.id === selectedStoreId) || null;
  const { periodId, currentPeriod } = useSavedPeriod();
  const { themeId } = useSavedTheme();
  const [selectedYear, setSelectedYear] = useState(year);
  const [selectedPeriodId, setSelectedPeriodId] = useState(periodId);
  const [selectedThemeId, setSelectedThemeId] = useState(themeId);
  const { periodsMap } = useThemePeriods();
  const period = selectedPeriodId ? periodsMap.get(selectedPeriodId) : null;

  const { campaigns, storeCampaigns } = useThemes(
    year || undefined,
    storeId || undefined,
    period?.startDate,
    period?.endDate,
  );

  const changeYear = (year: number) => {
    setSelectedYear(year);
    setSelectedPeriodId(null);
    setSelectedThemeId(null);
  };

  const changePeriod = (id: string | null) => {
    setSelectedPeriodId(id);
    setSelectedThemeId(null);
  };

  const changeStore = (storeId: number | null) => {
    setSelectedStoreId(storeId);
  };

  const selectedTheme =
    storeCampaigns?.find((t) => t.id === selectedThemeId) || campaigns?.find((t) => t.id === selectedThemeId);

  const handleFindProduct = async ({ productId }: FindProductForm) => {
    if (productId) {
      try {
        const { data: product, error: fetchError } = await getProduct(productId);

        if (fetchError) {
          setError(fetchError);
          return;
        }
        if ((product.basket ?? "") === "T" && (product?.abv ?? 0) > 8.0) {
          setError("Tilausvalikoimaan kuuluvia yli 8.0% vahvuisia tuotteita ei saa laittaa esillepanoon.");
          setProduct(undefined);
          return;
        }
        if (product.statusId === "K") {
          setError("Poistettuja tuotteita ei saa laittaa esillepanoon.");
          setProduct(undefined);
          return;
        }
        if ((product.basket ?? "") === "Y") {
          const selectedStoreIdString = selectedStore?.id?.toString();
          if (selectedStoreIdString && !product.deliveryStores.includes(selectedStoreIdString)) {
            setError(
              `Valittu myymälä (${selectedStore?.id ?? ""} ${
                selectedStore?.name ?? ""
              }) ei ole paikallisen tilausvalikoimatuotteen tilausmyymälänä. Tuotetta ei saa laittaa esillepanoon.`,
            );
            setProduct(undefined);
            return;
          }
        }
        if (product) {
          setError("");
          setProduct(product);

          // check if product has been at the display less than 12 months ago
          await checkLatestDisplay({ productId });
        }
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("unkown error at handleFindProduct");
        }
        setProduct(undefined);
      }
    }
  };

  const checkLatestDisplay = async ({ productId }: FindProductForm) => {
    const { data: latestDisplayData, error: fetchError } = await getLatestDisplayDate(storeId ?? 0, productId);

    if (fetchError) {
      setError(fetchError);
      return;
    }

    if (latestDisplayData) {
      const latestDate = toDate(latestDisplayData.endDate);
      const shouldNotBeDisplayedAfter = new Date();
      shouldNotBeDisplayedAfter.setFullYear(new Date().getFullYear() - 1);

      if (latestDate > shouldNotBeDisplayedAfter) {
        setLatestSpecialDisplay(latestDisplayData);
        setModalOpen(true);
      }
    }
  };

  return (
    <Box>
      <TooFrequentModal
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
        latestSpecialDisplay={latestSpecialDisplay}
      />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box display="flex" alignItems="center">
            <Typography variant="h2" style={{ padding: 0, margin: 0 }}>
              Uusi esillepano
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box mr={-2}>
            <IconButton onClick={onCancel} title="Sulje">
              <CloseIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      <Box py={{ xs: 1, sm: 2 }}>
        <StoreSelect storeId={selectedStoreId} setStoreId={changeStore} required />
      </Box>
      <Box py={{ xs: 1, sm: 2 }}>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={1} wrap="nowrap" alignItems="center">
            <Grid item>
              <YearSelect year={year} setYear={changeYear} required />
            </Grid>
            <Grid item xs>
              <PeriodSelect
                year={year}
                setYear={changeYear}
                periodId={selectedPeriodId}
                setPeriodId={changePeriod}
                currentPeriodId={currentPeriod?.id}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {selectedPeriodId && (
        <Box py={{ xs: 1, sm: 2 }}>
          <ThemeSelect
            year={selectedYear}
            periodId={selectedPeriodId}
            storeId={selectedStoreId}
            themeId={selectedThemeId || null}
            setThemeId={setSelectedThemeId}
            hidePastThemes
          />
        </Box>
      )}

      {!period ? (
        <Box>Valitse jakso</Box>
      ) : (
        <form onSubmit={handleProductFindSubmit(handleFindProduct)}>
          <Box display="flex" flexDirection="row" alignItems="stretch" py={{ xs: 1, sm: 2 }}>
            <TextField {...registerProductFind("productId")} label="Tuotenumero" variant="outlined" required />
            <Button variant="contained" type="submit" size="large">
              Hae
            </Button>
          </Box>
        </form>
      )}

      {error && <Box py={{ xs: 1, sm: 2 }}>{error}</Box>}

      {product && selectedStore && period && (
        <Box py={{ xs: 2, sm: 4 }}>
          <SpecialDisplayForm
            product={product}
            store={selectedStore}
            period={period}
            theme={selectedTheme}
            year={year}
            onSave={onSave}
            onCancel={onCancel}
          />
        </Box>
      )}
    </Box>
  );
};
