import React from "react";
import clsx from "clsx";
import { Toolbar, makeStyles, Theme, createStyles, lighten } from "@material-ui/core";

interface Props {
  hasSelections: boolean;
  actionsComponent: JSX.Element;
  filtersComponent: JSX.Element;
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      paddingBottom: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  }),
);

export const TableToolbar = ({ hasSelections, actionsComponent, filtersComponent }: Props): JSX.Element => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: hasSelections,
      })}
    >
      {hasSelections ? actionsComponent : filtersComponent}
    </Toolbar>
  );
};
