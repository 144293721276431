import React from "react";
import { OrderDirection } from "../../../utils/order";
import MuiTableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableCell from "@material-ui/core/TableCell";
import { Checkbox, Hidden, HiddenProps } from "@material-ui/core";

export interface HeadCell extends HiddenProps {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  sortable: boolean;
  isCheckbox?: boolean;
}

interface Props {
  headCells: HeadCell[];
  order: OrderDirection;
  orderBy: string;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  isAllSelected: () => boolean;
  onSelectAllClick: () => void;
}

export const TableHead = ({
  headCells,
  order,
  orderBy,
  onRequestSort,
  isAllSelected,
  onSelectAllClick,
}: Props): JSX.Element => {
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <MuiTableHead>
      <TableRow>
        {headCells.map(
          ({
            id,
            disablePadding,
            label,
            numeric,
            sortable,
            lgDown,
            lgUp,
            mdDown,
            mdUp,
            smDown,
            smUp,
            xlDown,
            xlUp,
            xsDown,
            xsUp,
            isCheckbox,
          }) => (
            <Hidden key={id} {...{ lgDown, lgUp, mdDown, mdUp, smDown, smUp, xlDown, xlUp, xsDown, xsUp }}>
              {isCheckbox ? (
                <TableCell padding={disablePadding ? "none" : "checkbox"}>
                  <Checkbox color="primary" checked={isAllSelected()} onClick={() => onSelectAllClick()} />
                </TableCell>
              ) : (
                <TableCell
                  align={numeric ? "right" : "left"}
                  padding={disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === id ? order : false}
                >
                  {sortable ? (
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? order : "asc"}
                      onClick={createSortHandler(id)}
                      hideSortIcon={!sortable}
                    >
                      {label}
                    </TableSortLabel>
                  ) : (
                    <span>{label}</span>
                  )}
                </TableCell>
              )}
            </Hidden>
          ),
        )}
        <TableCell></TableCell>
      </TableRow>
    </MuiTableHead>
  );
};
