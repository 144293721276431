import React from "react";
import { createStyles, makeStyles, TextField, Theme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: "12rem",
      [theme.breakpoints.down("sm")]: {
        minWidth: "9.2rem",
        maxWidth: "9.2rem",
      },
    },
  }),
);

interface Props {
  year: number;
  setYear: (value: number) => void;
  required?: boolean;
  small?: boolean;
  disabled?: boolean;
}
export const YearSelect = ({
  year,
  setYear,
  required = false,
  small = false,
  disabled = false,
}: Props): JSX.Element => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const years = [currentYear - 1, currentYear, currentYear + 1];

  return (
    <Autocomplete
      id="year"
      className={classes.root}
      disableClearable
      disabled={disabled}
      options={years}
      getOptionLabel={(option) => option.toString()}
      onChange={(event, value) => setYear(value)}
      value={year || currentYear}
      renderInput={(props) => <TextField {...props} variant="outlined" label="Vuosi" required={required} />}
      size={small ? "small" : "medium"}
    />
  );
};
