import React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DisplayStatus, displayStatuses } from "../../api/specialDisplays";

interface Props {
  type: DisplayStatus | null;
  setType: (value: DisplayStatus | null) => void;
  required?: boolean;
  small?: boolean;
  disabled?: boolean;
}
export const DisplayStatusSelect = ({
  type,
  setType,
  required = false,
  small = false,
  disabled = false,
}: Props): JSX.Element => {
  return (
    <Autocomplete
      id="displayStatus"
      options={displayStatuses}
      getOptionLabel={(option) => `${option.name} (${option.id})`}
      onChange={(_, value) => setType(value)}
      value={type}
      renderInput={(props) => <TextField {...props} variant="outlined" label="Voimassaolo" required={required} />}
      fullWidth
      size={small ? "small" : "medium"}
      disabled={disabled}
      style={{ paddingLeft: "6px", minWidth: "170px" }}
    />
  );
};
