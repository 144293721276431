import React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Campaign } from "../../api/themes";
import { formatDate, localDate } from "../../utils/date";
import { useThemes } from "../../hooks/useThemes";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import { useThemePeriods } from "../../hooks/useThemePeriods";
import startOfYear from "date-fns/startOfYear";
import endOfYear from "date-fns/endOfYear";

interface Props {
  year: number;
  periodId: string | null;
  storeId: number | null;
  themeId: number | null;
  setThemeId: (value: number | null) => void;
  hidePastThemes?: boolean;
  required?: boolean;
  small?: boolean;
}
export const ThemeSelect = ({
  year,
  periodId,
  storeId,
  themeId,
  setThemeId,
  hidePastThemes = false,
  required = false,
  small = false,
}: Props): JSX.Element => {
  const [inputValue, setInputValue] = React.useState("");
  const { periodsMap } = useThemePeriods();
  const period = (periodId && periodsMap.get(periodId)) || null;
  const { campaigns, storeCampaigns, isLoading } = useThemes(
    year || undefined,
    storeId || undefined,
    period?.startDate || startOfYear(new Date(year, 0)),
    period?.endDate || endOfYear(new Date(year, 0)),
  );

  const options: Campaign[] = (campaigns as Campaign[]).concat(storeCampaigns as Campaign[]);
  options.sort((a, b) =>
    a.startDate < b.startDate ? -1 : a.startDate > b.startDate ? 1 : a.endDate < b.endDate ? -1 : 1,
  );
  const selectedTheme = options.find((t) => t.id === themeId) || null;

  return (
    <Autocomplete
      id="theme"
      options={options.filter((c) => !hidePastThemes || isBefore(localDate(), c.endDate)) || []}
      getOptionLabel={(theme) => `${theme.name} (${formatDate(theme.startDate)} - ${formatDate(theme.endDate)})`}
      groupBy={(theme) =>
        isBefore(localDate(), theme.startDate)
          ? "Tulevat teemat"
          : isAfter(localDate(), theme.endDate)
          ? "Päättyneet teemat"
          : "Meneillään olevat teemat"
      }
      value={selectedTheme}
      onChange={(_, value: Campaign | null) => setThemeId(value?.id || null)}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} name="theme" label="Esillepanoteema" variant="outlined" required={required} />
      )}
      loading={isLoading}
      loadingText="Ladataan..."
      noOptionsText="Ei valittavia teemoja"
      fullWidth
      size={small ? "small" : "medium"}
    />
  );
};
