import format from "date-fns/format";
import fiLocale from "date-fns/locale/fi";
import { utcToZonedTime, format as formatTz } from "date-fns-tz";
import { endOfDay } from "date-fns";
import { DisplayStatusId } from "../api/specialDisplays";

export const formatDate = (date: Date, defaultValue = "-"): string => {
  return date ? format(localDate(date), "d.M.yyyy", { locale: fiLocale }) : defaultValue;
};

export const formatDateTime = (date: Date, defaultValue = "-"): string => {
  return date ? format(localDate(date), "d.M.yyyy HH:mm:ss", { locale: fiLocale }) : defaultValue;
};

const displayStatus = (dateStart: Date, dateEnd: Date, indefinitely: boolean): string => {
  const today = endOfDay(new Date());
  if (indefinitely) {
    // started indefinitely item
    return DisplayStatusId.INDEFINITELY;
  }
  if (today < dateStart) {
    // future display
    return DisplayStatusId.UPCOMING;
  } else if (today > dateEnd) {
    // past display
    return DisplayStatusId.PAST;
  }
  // active display
  return DisplayStatusId.ACTIVE;
};

export const formatDatePeriod = (dateStart: Date, dateEnd: Date, indefinitely: boolean, defaultValue = "-"): string => {
  if (!dateStart && !dateEnd) return defaultValue;
  const localDateStart = localDate(dateStart);
  const localDateEnd = localDate(dateEnd);
  const start = dateStart ? format(localDateStart, "d.M.yyyy", { locale: fiLocale }) : "";
  const end = dateEnd ? format(localDateEnd, "d.M.yyyy", { locale: fiLocale }) : "";
  const statusKey = displayStatus(localDateStart, localDateEnd, indefinitely);
  return `${statusKey}\u00a0\u00a0${start}\u00a0- ${end}`;
};

export const localDate = (date?: Date): Date => {
  return utcToZonedTime(date || new Date(), "Europe/Helsinki");
};

export const localDateTime = (date: Date): string =>
  formatTz(date, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: "Europe/Helsinki" });

export const formatISODate = (date: Date): string => formatTz(date, "yyyy-MM-dd", { timeZone: "Europe/Helsinki" });
