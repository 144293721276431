import React from "react";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { StoreSelect } from "../filter/StoreSelect";
import { ThemeSelect } from "../filter/ThemeSelect";
import { PeriodSelect } from "../filter/PeriodSelect";
import { YearSelect } from "../filter/YearSelect";
import { useSavedPeriod } from "../../hooks/useSavedPeriod";
import { useSavedYear } from "../../hooks/useSavedYear";
import { useSavedTheme } from "../../hooks/useSavedTheme";
import { useSavedStore } from "../../hooks/useSavedStore";
import { SpecialDisplayTypeSelect } from "../filter/SpecialDisplayTypeSelect";
import { DisplaySubTypeSelect } from "../filter/DisplaySubTypeSelect";
import { useSavedSpecialDisplayType } from "../../hooks/useSavedSpecialDisplayType";
import { useSavedDisplaySubType } from "../../hooks/useSavedDisplaySubType";
import {
  SpecialDisplayType,
  ProductDistributor,
  SpecialDisplayTypeId,
  DisplaySubType,
  dollySubTypes,
  coldCabinetSubTypes,
  DisplayStatus,
} from "../../api/specialDisplays";
import { Switch } from "../common/Switch";
import { useSavedActiveOnly } from "../../hooks/useSavedActiveOnly";
import { ProductDistributorSelect } from "../filter/ProductDistributorSelect";
import { useSavedProductDistributor } from "../../hooks/useSavedProductDistributor";
import { useSavedDisplayStatus } from "../../hooks/useSavedDisplayStatus";

export const FilterToolbar = (): JSX.Element => {
  const { storeId, setStoreId } = useSavedStore();
  const { year, setYear } = useSavedYear();
  const { periodId, setPeriodId, currentPeriod } = useSavedPeriod();
  const { themeId, setThemeId } = useSavedTheme();
  const { setSpecialDisplayTypeId, specialDisplayType } = useSavedSpecialDisplayType();
  const { setDisplaySubTypeId, displaySubType } = useSavedDisplaySubType();
  const { setProductDistributorId, productDistributor } = useSavedProductDistributor();
  const { activeOnly, setActiveOnly } = useSavedActiveOnly();
  const { setDisplayStatusId, displayStatus } = useSavedDisplayStatus();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  const changeYear = (year: number) => {
    setYear(year);
    setPeriodId(null);
    setThemeId(null);
  };

  const changePeriod = (periodId: string | null) => {
    setPeriodId(periodId);
    setThemeId(null);
  };

  const hasSubTypeDolly =
    specialDisplayType?.id === SpecialDisplayTypeId.DOLLY || specialDisplayType?.id === SpecialDisplayTypeId.MINIDOLLY;
  const hasSubTypeColdCabinet = specialDisplayType?.id === SpecialDisplayTypeId.COLD_CABINET;
  const disabled = !hasSubTypeDolly && !hasSubTypeColdCabinet;

  const setSpecialDisplayType = (value: SpecialDisplayType | null) => {
    if (value?.id !== specialDisplayType?.id) {
      setDisplaySubTypeId(undefined);
    }
    setSpecialDisplayTypeId(value?.id || null);
  };

  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid item xs={12} sm={11} md={6} lg={5} xl={6}>
        <StoreSelect storeId={storeId} setStoreId={setStoreId} small={small} />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={7} xl={6}>
        <Grid container direction="row" spacing={1} wrap="nowrap" alignItems="center">
          <Grid item>
            <YearSelect year={year} setYear={changeYear} small={small} disabled={activeOnly} />
          </Grid>
          <Grid item xs>
            <PeriodSelect
              year={year}
              setYear={changeYear}
              periodId={periodId}
              setPeriodId={changePeriod}
              currentPeriodId={currentPeriod?.id}
              small={small}
              disabled={activeOnly}
              statusType={displayStatus || null}
              setStatusType={(value: DisplayStatus | null) => setDisplayStatusId(value?.id || null)}
              showStatusType={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={11} md={6} lg={5} xl={4}>
        <ThemeSelect
          storeId={storeId}
          year={year}
          periodId={periodId || null}
          themeId={themeId || null}
          setThemeId={setThemeId}
          small={small}
        />
      </Grid>
      <Grid item xs={6} sm={3} md={3} lg={2} xl={2}>
        <SpecialDisplayTypeSelect type={specialDisplayType || null} setType={setSpecialDisplayType} small={small} />
      </Grid>
      <Grid item xs={6} sm={3} md={3} lg={2} xl={2}>
        <DisplaySubTypeSelect
          type={displaySubType || null}
          setType={(value: DisplaySubType | null) => setDisplaySubTypeId(value?.id || null)}
          subTypes={hasSubTypeDolly ? dollySubTypes : coldCabinetSubTypes}
          small={small}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6} sm={3} md={3} lg={2} xl={2}>
        <ProductDistributorSelect
          type={productDistributor || null}
          setType={(value: ProductDistributor | null) => setProductDistributorId(value?.id || null)}
          small={small}
        />
      </Grid>
      <Grid item xs={6} sm={3} md={2} lg={1} xl={2}>
        <Switch
          name="activeOnly"
          label="Vain aktiiviset"
          checked={activeOnly}
          onChange={(value) => setActiveOnly(value)}
        />
      </Grid>
    </Grid>
  );
};
