import React from "react";
import { Box } from "@material-ui/core";
import ThemesTable from "../components/themes/ThemesTable";

const ListThemes = (): JSX.Element => {
  return (
    <Box p={{ xs: 0, sm: 2 }}>
      <h1>Teemat ja jaksot</h1>
      <ThemesTable />
    </Box>
  );
};

export default ListThemes;
