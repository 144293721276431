import { ApiResponse } from "../utils/response";
import { getFromApi, putToApi, postToApi, deleteToApi } from "../utils/request";
import { StoreId, Store } from "./stores";
import { ProductId, Product } from "./products";
import { Period, PeriodId, ThemeCampaign, ThemeCampaignId } from "./themes";
import format from "date-fns-tz/format";
import { getProductId } from "../utils/product";
import { IPublicClientApplication } from "@azure/msal-browser";

export enum Season {
  SPRING = "SPRING",
  SUMMER = "SUMMER",
  FALL = "FALL",
  WINTER = "WINTER",
  CHRISTMAS = "CHRISTMAS",
}

export interface SpecialDisplayFilter {
  startDate?: string;
  endDate?: string;
  "store.id"?: string;
  specialDisplayType?: SpecialDisplayTypeId;
  "product.distributorId"?: ProductDistributorId;
  "product.id"?: string;
}

export enum PackageType {
  BOTTLE = "packageType_pullo",
  PLASTIC_BOTTLE = "packageType_muovipullo",
  CAN = "packageType_tolkki",
  BOX = "packageType_hanapakkaus",
  BAG = "packageType_viinipussi",
  CARTON = "packageType_kartonkitolkki",
  OTHER = "packageType_muu",
  BARREL = "packageType_tynnyri",
  GLASS_JAR = "packageType_lasipurkki",
}

export const units: Record<PackageType, { name: string; shortName: string }> = {
  [PackageType.BOTTLE]: { name: "pulloa", shortName: "plo" },
  [PackageType.PLASTIC_BOTTLE]: { name: "muovipulloa", shortName: "plo" },
  [PackageType.CAN]: { name: "tölkkiä", shortName: "tlk" },
  [PackageType.BOX]: { name: "hanapakkausta", shortName: "pkt" },
  [PackageType.BAG]: { name: "viinipussia", shortName: "pss" },
  [PackageType.CARTON]: { name: "kartonkitölkkiä", shortName: "kpl" },
  [PackageType.BARREL]: { name: "tynnyriä", shortName: "kpl" },
  [PackageType.GLASS_JAR]: { name: "lasipurkkia", shortName: "kpl" },
  [PackageType.OTHER]: { name: "kappaletta", shortName: "kpl" },
};

export enum SpecialDisplayTypeId {
  DOLLY = "DOLLY",
  MINIDOLLY = "MINIDOLLY",
  SPARKLING_TABLE = "SPARKLING_TABLE",
  COLD_CABINET = "COLD_CABINET",
  SPECIAL = "SPECIAL",
  CRATES = "CRATES",
}

export interface SpecialDisplayType {
  id: SpecialDisplayTypeId;
  name: string;
}

export const specialDisplayTypes: SpecialDisplayType[] = [
  { id: SpecialDisplayTypeId.DOLLY, name: "Dolly" },
  { id: SpecialDisplayTypeId.MINIDOLLY, name: "Minidolly" },
  { id: SpecialDisplayTypeId.SPARKLING_TABLE, name: "Kuohuviinipöytä" },
  { id: SpecialDisplayTypeId.COLD_CABINET, name: "Kylmäkaappi" },
  { id: SpecialDisplayTypeId.SPECIAL, name: "Erityisesillepano" },
  { id: SpecialDisplayTypeId.CRATES, name: "Laatikkomassa" },
];

export enum DisplaySubTypeId {
  DOLLY_EP1 = "DOLLY_EP1",
  DOLLY_EP2 = "DOLLY_EP2",
  DOLLY_EP3 = "DOLLY_EP3",
  COLD_CABINET_1 = "COLD_CABINET_1",
  COLD_CABINET_2 = "COLD_CABINET_2",
  COLD_CABINET_3 = "COLD_CABINET_3",
}
export interface DisplaySubType {
  id: DisplaySubTypeId;
  name: string;
}

export const dollySubTypes: DisplaySubType[] = [
  { id: DisplaySubTypeId.DOLLY_EP1, name: "Dolly-ep" },
  { id: DisplaySubTypeId.DOLLY_EP2, name: "Dolly-ep 2" },
  { id: DisplaySubTypeId.DOLLY_EP3, name: "Dolly-ep 3" },
];

export const coldCabinetSubTypes: DisplaySubType[] = [
  { id: DisplaySubTypeId.COLD_CABINET_1, name: "Kylmäkaappi-ep" },
  { id: DisplaySubTypeId.COLD_CABINET_2, name: "Kylmäkaappi-ep 2" },
  { id: DisplaySubTypeId.COLD_CABINET_3, name: "Kylmäkaappi-ep 3" },
];

export enum ProductDistributorId {
  OLVI = "02",
  HARTWALL = "04",
  SINEBRYCHOFF = "06",
  AKVA = "20",
  ALTIA = "30",
  SKANLOG = "40",
  ME_GROUP = "88",
  ORDERSELECTION = "91",
}

export interface ProductDistributor {
  id: ProductDistributorId;
  name: string;
}

export const productDistributors: ProductDistributor[] = [
  { id: ProductDistributorId.OLVI, name: `Olvi (${ProductDistributorId.OLVI})` },
  { id: ProductDistributorId.HARTWALL, name: `Hartwall (${ProductDistributorId.HARTWALL})` },
  { id: ProductDistributorId.SINEBRYCHOFF, name: `Sinebrychoff (${ProductDistributorId.SINEBRYCHOFF})` },
  { id: ProductDistributorId.AKVA, name: `Akva (${ProductDistributorId.AKVA})` },
  { id: ProductDistributorId.ALTIA, name: `Altia (${ProductDistributorId.ALTIA})` },
  { id: ProductDistributorId.SKANLOG, name: `Skanlog (${ProductDistributorId.SKANLOG})` },
  { id: ProductDistributorId.ME_GROUP, name: `ME Group (${ProductDistributorId.ME_GROUP})` },
  { id: ProductDistributorId.ORDERSELECTION, name: `T-suoratoimittaja (${ProductDistributorId.ORDERSELECTION})` },
];

export enum DisplayStatusId {
  ACTIVE = "A",
  PAST = "L",
  UPCOMING = "T",
  INDEFINITELY = "V",
}

export interface DisplayStatus {
  id: DisplayStatusId;
  name: string;
}

export const displayStatuses: DisplayStatus[] = [
  { id: DisplayStatusId.ACTIVE, name: `Aktiivinen` },
  { id: DisplayStatusId.PAST, name: `Loppunut` },
  { id: DisplayStatusId.UPCOMING, name: `Tulossa` },
  { id: DisplayStatusId.INDEFINITELY, name: `Toistaiseksi` },
];

export interface SpecialDisplayFormData {
  store: StoreId;
  product: ProductId;
  period: PeriodId;
  theme: ThemeCampaignId;
  year: number;
  specialDisplayType: SpecialDisplayType;
  displaySubType?: DisplaySubType | null;
  amount: number;
  startDate: Date;
  endDate: Date;
  comment?: string;
  indefinitely: boolean;
}

export interface SpecialDisplay {
  id: string;
  num?: number;
  store: Store;
  product: Product;
  period: Period;
  theme: ThemeCampaign;
  specialDisplayType: SpecialDisplayType;
  displaySubType?: DisplaySubType;
  amount: number;
  startDate: Date;
  endDate: Date;
  comment?: string;
  createdDate: Date;
  updatedDate?: Date;
  updatedBy?: string;
  deletedDate?: Date;
  deletedBy?: string;
  indefinitely: boolean;
}

export interface FindSpecialDisplaysParams {
  page: number;
  pageSize: number;
  orderBy?: string;
  orderDirection?: string;
  filters?: SpecialDisplayFilter;
}

export const getLatestDisplayDate = async (
  storeId: number,
  productId: string,
): Promise<ApiResponse<SpecialDisplay>> => {
  return await getFromApi<SpecialDisplay>("/v1/specialdisplays/latest", {
    filters: { "store.id": storeId.toString() ?? "", "product.id": getProductId(productId) },
  });
};

export const createSpecialDisplay = async (
  authProvider: IPublicClientApplication,
  item: SpecialDisplayFormData,
): Promise<ApiResponse<SpecialDisplay>> => {
  return await postToApi<SpecialDisplay>(authProvider, `/v1/specialdisplays`, {
    ...item,
    startDate: format(item.startDate, "yyyy-MM-dd", { timeZone: "Europe/Helsinki" }),
    endDate: format(item.endDate, "yyyy-MM-dd", { timeZone: "Europe/Helsinki" }),
  });
};

export const updateSpecialDisplay = async (
  authProvider: IPublicClientApplication,
  id: string,
  item: SpecialDisplayFormData,
): Promise<ApiResponse<SpecialDisplay>> => {
  return await putToApi<SpecialDisplay>(authProvider, `/v1/specialdisplays/${id}`, {
    ...item,
    startDate: format(item.startDate, "yyyy-MM-dd", { timeZone: "Europe/Helsinki" }),
    endDate: format(item.endDate, "yyyy-MM-dd", { timeZone: "Europe/Helsinki" }),
  });
};

export const deleteSpecialDisplay = async (
  authProvider: IPublicClientApplication,
  id: string,
): Promise<ApiResponse<SpecialDisplay>> => {
  return await deleteToApi<SpecialDisplay>(authProvider, `/v1/specialdisplays/${id}`);
};
