/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";

const STORE_LOCAL_STORAGE_KEY = "specialDisplays.storeId";
const YEAR_LOCAL_STORAGE_KEY = "specialDisplays.year";
const PERIOD_LOCAL_STORAGE_KEY = "specialDisplays.periodId";
const THEME_LOCAL_STORAGE_KEY = "specialDisplays.themeId";
const SPECIAL_DISPLAY_TYPE_LOCAL_STORAGE_KEY = "specialDisplays.specialDisplayType";
const DISPLAY_SUB_TYPE_LOCAL_STORAGE_KEY = "specialDisplays.displaySubType";
const PRODUCT_DISTRIBUTOR_LOCAL_STORAGE_KEY = "specialDisplays.productDistributor";
const ACTIVE_ONLY_LOCAL_STORAGE_KEY = "specialDisplays.activeOnly";
const DISPLAY_STATUS_LOCAL_STORAGE_KEY = "specialDisplays.displayStatusId";

const getSelectedStoreId = (): number | null | undefined => {
  const savedStoreId = window.localStorage.getItem(STORE_LOCAL_STORAGE_KEY);
  if (savedStoreId) return parseInt(savedStoreId);
  return undefined;
};
const getSelectedYear = (): number | undefined => {
  const savedYear = window.localStorage.getItem(YEAR_LOCAL_STORAGE_KEY);
  if (savedYear) return parseInt(savedYear);
  return undefined;
};
const getSelectedPeriodId = (): string | null | undefined => {
  const savedPeriodId = window.localStorage.getItem(PERIOD_LOCAL_STORAGE_KEY);
  if (savedPeriodId) return savedPeriodId;
  return undefined;
};
const getSelectedThemeId = (): number | null | undefined => {
  const savedThemeId = window.localStorage.getItem(THEME_LOCAL_STORAGE_KEY);
  if (savedThemeId) return parseInt(savedThemeId);
  return undefined;
};
const getSelectedSpecialDisplayTypeId = (): string | null | undefined => {
  const savedDisplayType = window.localStorage.getItem(SPECIAL_DISPLAY_TYPE_LOCAL_STORAGE_KEY);
  if (savedDisplayType) return savedDisplayType;
  return undefined;
};
const getSelectedProductDistributor = (): string | null | undefined => {
  const savedProductDistributor = window.localStorage.getItem(PRODUCT_DISTRIBUTOR_LOCAL_STORAGE_KEY);
  if (savedProductDistributor) return savedProductDistributor;
  return undefined;
};
const getActiveOnly = (): boolean => {
  const savedActiveOnly = window.localStorage.getItem(ACTIVE_ONLY_LOCAL_STORAGE_KEY);
  if (savedActiveOnly) return savedActiveOnly.toLowerCase() === "true";
  return false;
};
const getDisplayStatusId = (): string | null | undefined => {
  const displayStatusId = window.localStorage.getItem(DISPLAY_STATUS_LOCAL_STORAGE_KEY);
  if (displayStatusId) return displayStatusId;
  return undefined;
};

interface LocalStorageContextType {
  storeId?: number | null;
  setStoreId: (id?: number | null) => void;
  year?: number;
  setYear: (id?: number) => void;
  periodId?: string | null;
  setPeriodId: (id?: string | null) => void;
  themeId?: number | null;
  setThemeId: (id?: number | null) => void;
  specialDisplayTypeId?: string | null;
  setSpecialDisplayTypeId: (id?: string | null) => void;
  displaySubTypeId?: string | null;
  setDisplaySubTypeId: (id?: string | null) => void;
  productDistributorId?: string | null;
  setProductDistributorId: (id?: string | null) => void;
  activeOnly: boolean;
  setActiveOnly: (value: boolean) => void;
  displayStatusId?: string | null;
  setDisplayStatusId: (id?: string | null) => void;
}
const LocalStorageContext = React.createContext<LocalStorageContextType>({
  storeId: undefined,
  setStoreId: (id?: number | null) => {},
  year: undefined,
  setYear: (id?: number) => {},
  periodId: undefined,
  setPeriodId: (id?: string | null) => {},
  themeId: undefined,
  setThemeId: (id?: number | null) => {},
  specialDisplayTypeId: undefined,
  setSpecialDisplayTypeId: (id?: string | null) => {},
  displaySubTypeId: undefined,
  setDisplaySubTypeId: (id?: string | null) => {},
  productDistributorId: undefined,
  setProductDistributorId: (id?: string | null) => {},
  activeOnly: false,
  setActiveOnly: (value: boolean) => {},
  displayStatusId: undefined,
  setDisplayStatusId: (id?: string | null) => {},
});

interface Props {
  children: React.ReactNode;
}

export const LocalStorageProvider = ({ children }: Props): JSX.Element => {
  const [storeState, setStoreState] = React.useState<number | null | undefined>(getSelectedStoreId());
  const [yearState, setYearState] = React.useState<number | undefined>(getSelectedYear());
  const [periodState, setPeriodState] = React.useState<string | null | undefined>(getSelectedPeriodId());
  const [themeState, setThemeState] = React.useState<number | null | undefined>(getSelectedThemeId());
  const [specialDisplayTypeState, setSpecialDisplayTypeState] = React.useState<string | null | undefined>(
    getSelectedSpecialDisplayTypeId(),
  );
  const [displaySubTypeState, setDisplaySubTypeState] = React.useState<string | null | undefined>(
    getSelectedSpecialDisplayTypeId(),
  );
  const [productDistributorState, setProductDistributorState] = React.useState<string | null | undefined>(
    getSelectedProductDistributor(),
  );
  const [activeOnlyState, setActiveOnlyState] = React.useState<boolean>(getActiveOnly());
  const [displayStatusState, setDisplayStatusState] = React.useState<string | null | undefined>(getDisplayStatusId());

  const setStore = (id?: number | null) => {
    if (id) {
      window.localStorage.setItem(STORE_LOCAL_STORAGE_KEY, id.toString());
    } else {
      window.localStorage.removeItem(STORE_LOCAL_STORAGE_KEY);
    }
    setStoreState(id);
  };
  const setYear = (year?: number) => {
    if (year) {
      window.localStorage.setItem(YEAR_LOCAL_STORAGE_KEY, year.toString());
    } else {
      window.localStorage.removeItem(YEAR_LOCAL_STORAGE_KEY);
    }
    setYearState(year);
  };
  const setPeriod = (id?: string | null) => {
    if (id) {
      window.localStorage.setItem(PERIOD_LOCAL_STORAGE_KEY, id);
    } else {
      window.localStorage.removeItem(PERIOD_LOCAL_STORAGE_KEY);
    }
    setPeriodState(id);
  };
  const setTheme = (id?: number | null) => {
    if (id) {
      window.localStorage.setItem(THEME_LOCAL_STORAGE_KEY, id.toString());
    } else {
      window.localStorage.removeItem(THEME_LOCAL_STORAGE_KEY);
    }
    setThemeState(id);
  };
  const setSpecialDisplayType = (id?: string | null) => {
    if (id) {
      window.localStorage.setItem(SPECIAL_DISPLAY_TYPE_LOCAL_STORAGE_KEY, id);
    } else {
      window.localStorage.removeItem(SPECIAL_DISPLAY_TYPE_LOCAL_STORAGE_KEY);
    }
    setSpecialDisplayTypeState(id);
  };
  const setDisplaySubType = (id?: string | null) => {
    if (id) {
      window.localStorage.setItem(DISPLAY_SUB_TYPE_LOCAL_STORAGE_KEY, id);
    } else {
      window.localStorage.removeItem(DISPLAY_SUB_TYPE_LOCAL_STORAGE_KEY);
    }
    setDisplaySubTypeState(id);
  };
  const setProductDistributor = (id?: string | null) => {
    if (id) {
      window.localStorage.setItem(PRODUCT_DISTRIBUTOR_LOCAL_STORAGE_KEY, id);
    } else {
      window.localStorage.removeItem(PRODUCT_DISTRIBUTOR_LOCAL_STORAGE_KEY);
    }
    setProductDistributorState(id);
  };
  const setActiveOnly = (value: boolean) => {
    if (value !== undefined && value !== null) {
      window.localStorage.setItem(ACTIVE_ONLY_LOCAL_STORAGE_KEY, value.toString());
    } else {
      window.localStorage.removeItem(ACTIVE_ONLY_LOCAL_STORAGE_KEY);
    }
    setActiveOnlyState(value);
  };

  const setDisplayStatus = (id?: string | null) => {
    if (id) {
      window.localStorage.setItem(DISPLAY_STATUS_LOCAL_STORAGE_KEY, id);
    } else {
      window.localStorage.removeItem(DISPLAY_STATUS_LOCAL_STORAGE_KEY);
    }
    setDisplayStatusState(id);
  };

  return (
    <LocalStorageContext.Provider
      value={{
        storeId: storeState,
        setStoreId: setStore,
        year: yearState,
        setYear: setYear,
        periodId: periodState,
        setPeriodId: setPeriod,
        themeId: themeState,
        setThemeId: setTheme,
        specialDisplayTypeId: specialDisplayTypeState,
        setSpecialDisplayTypeId: setSpecialDisplayType,
        displaySubTypeId: displaySubTypeState,
        setDisplaySubTypeId: setDisplaySubType,
        productDistributorId: productDistributorState,
        setProductDistributorId: setProductDistributor,
        activeOnly: activeOnlyState,
        setActiveOnly: setActiveOnly,
        displayStatusId: displayStatusState,
        setDisplayStatusId: setDisplayStatus,
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  );
};

export const useLocalStorage = (): LocalStorageContextType => React.useContext(LocalStorageContext);
