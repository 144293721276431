import React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { SpecialDisplayType, specialDisplayTypes } from "../../api/specialDisplays";

interface Props {
  type: SpecialDisplayType | null;
  setType: (value: SpecialDisplayType | null) => void;
  required?: boolean;
  small?: boolean;
}
export const SpecialDisplayTypeSelect = ({ type, setType, required = false, small = false }: Props): JSX.Element => {
  return (
    <Autocomplete
      id="specialDisplayType"
      options={specialDisplayTypes}
      getOptionLabel={(option) => option.name}
      onChange={(_, value) => setType(value)}
      value={type}
      renderInput={(props) => <TextField {...props} variant="outlined" label="Esillepanotyyppi" required={required} />}
      fullWidth
      size={small ? "small" : "medium"}
    />
  );
};
