import React from "react";
import { createStyles, IconButton, makeStyles, TextField, Theme, Box } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Period } from "../../api/themes";
import { formatDate, localDate } from "../../utils/date";
import { useThemePeriods } from "../../hooks/useThemePeriods";
import isBefore from "date-fns/isBefore";
import TodayIcon from "@material-ui/icons/Today";
import { DisplayStatusSelect } from "./DisplayStatusSelect";
import { DisplayStatus } from "../../api/specialDisplays";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  }),
);

interface Props {
  year: number;
  setYear: (value: number) => void;
  periodId: string | null;
  setPeriodId: (value: string | null) => void;
  showStatusType?: boolean;
  currentPeriodId?: string;
  hidePastPeriods?: boolean;
  required?: boolean;
  small?: boolean;
  disabled?: boolean;
  statusType?: DisplayStatus | null;
  setStatusType?: (value: DisplayStatus | null) => void | undefined;
}
export const PeriodSelect = ({
  year,
  setYear,
  periodId,
  setPeriodId,
  currentPeriodId,
  showStatusType = false,
  hidePastPeriods = false,
  required = false,
  small = false,
  disabled = false,
  statusType = null,
  setStatusType = () => undefined,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { periods, isLoading, periodsMap } = useThemePeriods();
  const selectedPeriod = (periodId && periodsMap.get(periodId)) || null;

  const handlePeriodChange = (value: Period | null) => {
    setPeriodId(value?.id || null);
  };

  const setCurrentYearAndPeriod = () => {
    if (currentPeriodId) {
      setYear(new Date().getFullYear());
      setPeriodId(currentPeriodId);
    }
  };

  const options = periods.find((py) => py.year === year)?.periods || [];

  return (
    <Box className={classes.root} pr={2}>
      <Autocomplete
        value={selectedPeriod}
        options={options.filter((p) => !hidePastPeriods || isBefore(localDate(), p.endDate)) || []}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(period: Period) =>
          `${period.name} (${formatDate(period.startDate)} - ${formatDate(period.endDate)})`
        }
        disabled={disabled}
        renderInput={(params) => <TextField {...params} variant="outlined" label="Jakso" required={required} />}
        onChange={(_, value: Period | null) => handlePeriodChange(value)}
        loading={isLoading}
        loadingText="Ladataan..."
        size={small ? "small" : "medium"}
        noOptionsText="Ei valittavia jaksoja"
        fullWidth
      />
      {showStatusType && (
        <DisplayStatusSelect type={statusType} setType={setStatusType} small={small} disabled={disabled} />
      )}
      <Box>
        <IconButton title="Kuluva vuosi ja jakso" onClick={() => setCurrentYearAndPeriod()} disabled={disabled}>
          <TodayIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
