import { Period } from "../api/themes";
import { useLocalStorage } from "./useLocalStorage";
import { useThemePeriods } from "./useThemePeriods";

export const useSavedPeriod = (): {
  periodId: string | null;
  setPeriodId: (value: string | null) => void;
  period: Period | null;
  currentPeriod?: Period;
} => {
  const { periodId, setPeriodId } = useLocalStorage();
  const { periodsMap, currentPeriod } = useThemePeriods();
  return {
    periodId: periodId || null,
    setPeriodId,
    period: periodId ? periodsMap.get(periodId) || null : null,
    currentPeriod,
  };
};
