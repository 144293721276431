import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Unauthorized } from "./pages/Unauthorized";
import { Loading } from "./pages/Loading";
import { ListSpecialDisplays } from "./pages/ListSpecialDisplays";
import { UserGroup } from "./api/users";
import { useUserGroups } from "./hooks/useUserGroups";
import ListThemes from "./pages/ListThemes";
import { NotFound } from "./pages/NotFound";
import { useSavedYear } from "./hooks/useSavedYear";
import { AccountInfo } from "@azure/msal-browser";

interface MainProps {
  activeAccount: AccountInfo | null;
}

function Main({ activeAccount }: MainProps): JSX.Element {
  const { userGroups, loading, error } = useUserGroups();
  const hasValidUserGroups = userGroups && userGroups.length > 0;
  const { year, setYear } = useSavedYear();

  useEffect(() => {
    if (!year) {
      setYear(new Date().getFullYear());
    }
  }, [setYear, year]);

  if (loading || !activeAccount || !!error) {
    return <Loading />;
  } else if (hasValidUserGroups) {
    return (
      <Routes>
        {userGroups?.includes(UserGroup.SPECIAL_DISPLAY_ADMIN) && (
          <Route path="/specialdisplays" element={<ListSpecialDisplays />} />
        )}
        {userGroups?.includes(UserGroup.THEME_ADMIN) && <Route path="/themes" element={<ListThemes />} />}
        <Route
          path="/"
          element={
            (userGroups?.includes(UserGroup.SPECIAL_DISPLAY_ADMIN) && <Navigate to="/specialdisplays" />) ||
            (userGroups?.includes(UserGroup.THEME_ADMIN) && <Navigate to="/themes" />) || <NotFound />
          }
        />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    );
  } else {
    return <Unauthorized />;
  }
}

export default Main;
