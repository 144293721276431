import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Store } from "../../api/stores";
import { useAllStores } from "../../hooks/useAllStores";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: "40rem",
      [theme.breakpoints.down("sm")]: {
        minWidth: "20rem",
        width: "100%",
      },
    },
  }),
);

interface Props {
  storeId: number | null;
  setStoreId: (value: number | null) => void;
  required?: boolean;
  small?: boolean;
}
export const StoreSelect = ({ storeId, setStoreId, required = false, small = false }: Props): JSX.Element => {
  const classes = useStyles();
  const { stores, isLoading } = useAllStores();
  const selectedStore = stores.find((s) => s.id === storeId) || null;

  return (
    <Autocomplete
      className={classes.root}
      value={selectedStore}
      options={stores}
      disableClearable={!storeId}
      getOptionLabel={(store: Store) => `${store.id} ${store.name}`}
      getOptionSelected={(option: Store, value: Store) => option.id === value.id}
      renderInput={(params) => (
        <TextField {...params} label="Myymälä" variant="outlined" color="primary" fullWidth required={required} />
      )}
      autoHighlight
      disablePortal
      onChange={(_, value: Store | null) => setStoreId(value?.id || null)}
      loading={isLoading}
      loadingText="Ladataan..."
      noOptionsText="Ei valittavia myymälöitä"
      fullWidth
      size={small ? "small" : "medium"}
    />
  );
};
