import { DisplayStatus, displayStatuses } from "../api/specialDisplays";
import { useLocalStorage } from "./useLocalStorage";

export const useSavedDisplayStatus = (): {
  displayStatusId?: string | null;
  setDisplayStatusId: (value?: string | null) => void;
  displayStatus?: DisplayStatus;
} => {
  const { displayStatusId, setDisplayStatusId } = useLocalStorage();
  const displayStatus = displayStatuses.find((s) => s.id === displayStatusId);
  return { displayStatusId, setDisplayStatusId, displayStatus };
};
