import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Grid,
  Typography,
  Box,
  TextField,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { isAfter, isBefore } from "date-fns";
import { upperFirst } from "lodash";
import {
  SpecialDisplayFormData,
  specialDisplayTypes,
  units,
  SpecialDisplayType,
  SpecialDisplay,
  PackageType,
  SpecialDisplayTypeId,
  dollySubTypes,
  coldCabinetSubTypes,
  DisplaySubType,
} from "../../api/specialDisplays";
import { Product } from "../../api/products";
import { Store } from "../../api/stores";
import { formatEur } from "../../utils/currency";
import { Period, StoreCampaign, ThemeCampaign } from "../../api/themes";
import { localDate } from "../../utils/date";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      marginTop: 0,
    },
    alert: {
      color: "#d32f2f",
      "& label": {
        color: "#d32f2f !important",
      },
      "& div": {
        color: "#d32f2f",
      },
    },
    "product-status-P": {
      color: "#CC0000",
    },
  }),
);

interface Props {
  product: Product;
  store: Store;
  period: Period;
  year: number;
  theme?: ThemeCampaign | StoreCampaign;
  onSave: (values: SpecialDisplayFormData) => void;
  onCancel: () => void;
  item?: SpecialDisplay;
  actions?: JSX.Element;
}
export const SpecialDisplayForm = ({
  product,
  store,
  period,
  year,
  theme,
  onSave,
  onCancel,
  item,
  actions,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { handleSubmit, register, getValues, control, formState, watch, setValue } = useForm<SpecialDisplayFormData>({
    defaultValues: item,
  });
  const { isDirty, errors } = formState;

  const isCurrentDisplay = item
    ? isAfter(localDate(), localDate(item.startDate)) && isBefore(localDate(), localDate(item.endDate))
    : false;
  const isPastDisplay = item ? isAfter(localDate(), localDate(item.endDate)) : false;
  const isFutureDisplay = item ? isBefore(localDate(), localDate(item.startDate)) : true;

  const isCurrentTheme = theme
    ? isAfter(localDate(), localDate(theme.startDate)) && isBefore(localDate(), localDate(theme.endDate))
    : false;
  const isPastTheme = theme ? isAfter(localDate(), localDate(theme.endDate)) : false;
  const isFutureTheme = theme ? isBefore(localDate(), localDate(theme.startDate)) : true;

  const isCurrentPeriod = period
    ? isAfter(localDate(), localDate(period.startDate)) && isBefore(localDate(), localDate(period.endDate))
    : false;
  const isPastPeriod = period ? isAfter(localDate(), localDate(period.endDate)) : false;
  const isFuturePeriod = period ? isBefore(localDate(), localDate(period.startDate)) : true;

  const indefinitelyAndOn = item ? item.indefinitely && isBefore(localDate(), localDate(item.endDate)) : true;

  const packageType: PackageType =
    item && item.product
      ? (item.product.packageTypeId[0] as PackageType)
      : product
      ? (product.packageTypeId[0] as PackageType)
      : PackageType.OTHER;
  const watchIndefinitely = watch("indefinitely", item ? item.indefinitely : false);

  let defaultDisplayType: SpecialDisplayType | undefined = undefined;
  if (theme?.name?.startsWith("Kuohuviinipöytä:")) {
    defaultDisplayType = specialDisplayTypes.find((t) => t.id === SpecialDisplayTypeId.SPARKLING_TABLE);
  } else if (theme?.name?.startsWith("1-piikki:") || theme?.name?.startsWith("2-piikki:")) {
    defaultDisplayType = specialDisplayTypes.find((t) => t.id === SpecialDisplayTypeId.SPECIAL);
  }

  const watchDisplayType = watch("specialDisplayType", item ? item.specialDisplayType : undefined);
  const hasSubTypeDolly =
    watchDisplayType?.id === SpecialDisplayTypeId.DOLLY || watchDisplayType?.id === SpecialDisplayTypeId.MINIDOLLY;
  const hasSubTypeColdCabinet = watchDisplayType?.id === SpecialDisplayTypeId.COLD_CABINET;
  const disabled = !hasSubTypeDolly && !hasSubTypeColdCabinet;

  const ProductImage = () => (
    <Box
      px={2}
      height={{ xs: 200, md: 400 }}
      width={{ xs: 100, md: 200 }}
      style={{
        backgroundImage: `url(https://images.alko.fi/t_digipruuvi,f_auto/cdn/${product.id})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50% 50%",
      }}
    ></Box>
  );

  const specialDislplayTypeOnChange = (value: SpecialDisplayType | null, onChange: (...event: any[]) => void) => {
    if (value?.id !== item?.specialDisplayType.id) {
      setValue("displaySubType", null);
    }
    onChange(value);
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <input {...register("product.id")} value={product.id} hidden readOnly />
      <input {...register("store.id")} value={store.id} hidden readOnly />
      <input {...register("period.id")} value={period.id || ""} hidden readOnly />
      <input {...register("theme.id")} value={theme?.id || ""} hidden readOnly />
      <input {...register("year")} value={year} hidden readOnly />
      <Grid container direction="row">
        <Grid item xs={6} md={3}>
          <Typography variant="h5" className={classes.heading}>
            Tuotenumero
          </Typography>
        </Grid>
        <Grid item xs={6} md={9}>
          <Typography>{product.id}</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="h5" className={classes.heading}>
            Tuotteen nimi
          </Typography>
        </Grid>
        <Grid item xs={6} md={9}>
          <Typography>{product.name}</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="h5" className={classes.heading}>
            Kategoria
          </Typography>
        </Grid>
        <Grid item xs={6} md={9}>
          <Typography>{upperFirst(product.mainGroupName[0])}</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="h5" className={classes.heading}>
            Hinta
          </Typography>
        </Grid>
        <Grid item xs={6} md={9}>
          <Typography>{formatEur(product.price)}</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="h5" className={classes.heading}>
            Kori
          </Typography>
        </Grid>
        <Grid item xs={6} md={9}>
          <Typography>{product.basket}</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="h5" className={classes.heading}>
            Maa
          </Typography>
        </Grid>
        <Grid item xs={6} md={9}>
          <Typography>{product.countryName}</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="h5" className={classes.heading}>
            Toimittaja
          </Typography>
        </Grid>
        <Grid item xs={6} md={9}>
          <Typography>{product.agentName}</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="h5" className={classes.heading}>
            Jakelija
          </Typography>
        </Grid>
        <Grid item xs={6} md={9}>
          <Typography>{product.distributor}</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="h5" className={classes.heading}>
            Varasto
          </Typography>
        </Grid>
        <Grid item xs={6} md={9}>
          <Typography>{product.distributorId}</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="h5" className={classes.heading}>
            Tila
          </Typography>
        </Grid>
        <Grid item xs={6} md={9}>
          <Typography className={classes[`product-status-${product.statusId}` as keyof typeof classes]}>
            {product.status}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box py={2}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Grid container direction="column" spacing={2}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={9} md={6} lg={5}>
                      <Controller
                        render={({ field: { onChange, onBlur, name, value } }) => (
                          <Autocomplete
                            options={specialDisplayTypes}
                            getOptionLabel={(option: SpecialDisplayType) => option.name}
                            getOptionSelected={(option, value) => option?.id === value?.id}
                            renderInput={(params) => (
                              <TextField {...params} name={name} label="Esillepanotyyppi" required />
                            )}
                            fullWidth
                            onChange={(_, value: SpecialDisplayType | null) =>
                              specialDislplayTypeOnChange(value, onChange)
                            }
                            onClose={onBlur}
                            disabled={isPastDisplay || isPastTheme}
                            value={value}
                            key="specialDisplayType"
                          />
                        )}
                        control={control}
                        name="specialDisplayType"
                        defaultValue={item ? item.specialDisplayType : defaultDisplayType}
                      />
                    </Grid>
                    <Grid item xs={12} sm={9} md={6} lg={5}>
                      <Controller
                        render={({ field: { onChange, onBlur, name, value } }) => (
                          <Autocomplete
                            options={hasSubTypeDolly ? dollySubTypes : coldCabinetSubTypes}
                            getOptionLabel={(option: DisplaySubType) => option.name}
                            getOptionSelected={(option, value) => option?.id === value?.id}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name={name}
                                label="Esillepanoalatyyppi"
                                required={hasSubTypeColdCabinet}
                              />
                            )}
                            fullWidth
                            onChange={(_, value: DisplaySubType | null) => onChange(value)}
                            onClose={onBlur}
                            disabled={isPastDisplay || isPastTheme || disabled}
                            value={value as DisplaySubType}
                            key="displaySubType"
                          />
                        )}
                        control={control}
                        name="displaySubType"
                        defaultValue={item?.displaySubType ? item.displaySubType : null}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={9} md={6}>
                    <TextField
                      {...register("amount")}
                      fullWidth
                      className={errors.amount && classes.alert}
                      label="Lukumäärä"
                      defaultValue={item ? item.amount : ""}
                      InputProps={{ endAdornment: units[packageType]?.name }}
                      inputProps={{ min: "1", max: "999", step: "1", pattern: "[0-9]*", inputMode: "numeric" }}
                      required
                      disabled={isPastDisplay || isPastTheme}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Grid container>
                      <Grid item xs>
                        <Controller
                          render={({ field: { ref, ...rest } }) => (
                            <DatePicker
                              {...rest}
                              autoOk
                              disableToolbar
                              fullWidth
                              format="dd.MM.yyyy"
                              inputVariant="standard"
                              label="Alkupvm"
                              variant="inline"
                              maxDate={getValues("endDate") || null}
                              required
                              disabled={isPastDisplay || isPastTheme}
                            />
                          )}
                          name="startDate"
                          control={control}
                          defaultValue={
                            item
                              ? localDate(item.startDate)
                              : isCurrentTheme
                              ? localDate()
                              : theme
                              ? localDate(theme.startDate)
                              : localDate()
                          }
                        />
                      </Grid>
                      <Grid item xs>
                        <Controller
                          render={({ field: { ref, ...rest } }) => (
                            <DatePicker
                              {...rest}
                              autoOk
                              disableToolbar
                              fullWidth
                              format="dd.MM.yyyy"
                              inputVariant="standard"
                              label="Loppupvm"
                              variant="inline"
                              minDate={getValues("startDate") || null}
                              required
                              disabled={isPastDisplay || isPastTheme || watchIndefinitely}
                            />
                          )}
                          name="endDate"
                          control={control}
                          defaultValue={
                            item
                              ? localDate(item.endDate)
                              : theme
                              ? localDate(theme.endDate)
                              : localDate(period.endDate)
                              ? localDate(period.endDate)
                              : new Date()
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={9} md={6}>
                    <FormControlLabel
                      disabled={!indefinitelyAndOn && (isPastDisplay || isPastTheme)}
                      control={
                        <Switch {...register("indefinitely")} defaultChecked={item ? item.indefinitely : false} />
                      }
                      label="Toistaiseksi voimassaoleva"
                    />
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <TextField
                      {...register("comment")}
                      fullWidth
                      label={item ? "Kirjoita muokkauksen syy" : "Kommentti"}
                      variant="outlined"
                      multiline
                      rows={3}
                      defaultValue=""
                      required={!!item}
                      disabled={isPastDisplay || isPastTheme || isPastPeriod}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs zeroMinWidth>
                <ProductImage />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Box py={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item container direction="row" spacing={2}>
            <Grid item>{actions}</Grid>
          </Grid>
          <Grid item container direction="row" spacing={2}>
            {(item
              ? isCurrentDisplay || isFutureDisplay
              : isCurrentTheme || isFutureTheme || isCurrentPeriod || isFuturePeriod) && (
              <>
                <Grid item>
                  <Button variant="contained" color="primary" type="submit" disabled={!isDirty}>
                    Tallenna
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="text" onClick={onCancel}>
                    {isDirty ? "Peruuta" : "Sulje"}
                  </Button>
                </Grid>
              </>
            )}
            {(item ? isPastDisplay : isPastTheme || isPastPeriod) && (
              <Grid item>
                <Button variant="outlined" color="primary" onClick={onCancel}>
                  Sulje
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
