import React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DisplaySubType } from "../../api/specialDisplays";

interface Props {
  type: DisplaySubType | null;
  setType: (value: DisplaySubType | null) => void;
  subTypes: DisplaySubType[];
  required?: boolean;
  small?: boolean;
  disabled: boolean;
}
export const DisplaySubTypeSelect = ({
  type,
  setType,
  subTypes,
  required = false,
  small = false,
  disabled,
}: Props): JSX.Element => {
  return (
    <Autocomplete
      id="specialDisplayType"
      options={subTypes}
      getOptionLabel={(option) => option.name}
      onChange={(_, value) => setType(value)}
      value={type}
      renderInput={(props) => <TextField {...props} variant="outlined" label="Alatyyppi" required={required} />}
      fullWidth
      size={small ? "small" : "medium"}
      disabled={disabled}
    />
  );
};
