import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from "@material-ui/core";
import { SpecialDisplay } from "../../api/specialDisplays";

interface Props {
  item: SpecialDisplay;
  onConfirm: (item: SpecialDisplay, comment: string) => void;
}
const StopDisplayButtonWithDialog = ({ item, onConfirm }: Props): JSX.Element => {
  const [endDisplayDialogOpen, setEndDisplayDialogOpen] = useState(false);
  const handleEndDisplayDialogOpen = () => setEndDisplayDialogOpen(true);
  const handleEndDisplayDialogClose = () => setEndDisplayDialogOpen(false);
  const [comment, setComment] = useState("");

  return (
    <>
      <Button variant="contained" color="secondary" onClick={handleEndDisplayDialogOpen}>
        Lopeta tuotteen esillepano
      </Button>
      <Dialog
        open={endDisplayDialogOpen}
        onClose={handleEndDisplayDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Lopetetaanko esillepano?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tuotteen {item.product.name} esillepano lopetetaan tähän päivään.
          </DialogContentText>
          <TextField
            fullWidth
            name="comment"
            label="Kirjoita lopettamisen syy"
            variant="outlined"
            multiline
            rows={3}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEndDisplayDialogClose} color="primary">
            Peruuta
          </Button>
          <Button onClick={() => onConfirm(item, comment)} color="primary" variant="contained" autoFocus>
            Lopeta tuotteen esillepano
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StopDisplayButtonWithDialog;
