import {
  Switch as MuiSwitch,
  SwitchClassKey,
  SwitchProps as MuiSwitchProps,
  withStyles,
  Theme,
  createStyles,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import React from "react";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface SwitchProps extends MuiSwitchProps {
  classes: Styles;
}

const AlkoSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 2,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: theme.palette.secondary.main,
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: theme.palette.secondary.main,
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 22,
      height: 22,
      borderRadius: 4,
    },
    track: {
      borderRadius: 6,
      border: `1px solid ${theme.palette.grey[600]}`,
      backgroundColor: theme.palette.grey[500],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }),
)(({ classes, ...props }: SwitchProps) => {
  return (
    <MuiSwitch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

interface Props {
  name: string;
  label: string;
  checked: boolean;
  onChange: (value: boolean) => void;
}
export const Switch = ({ name, label, checked, onChange }: Props): JSX.Element => {
  return (
    <FormGroup>
      <FormControlLabel
        control={<AlkoSwitch checked={checked} onChange={(event) => onChange(event.target.checked)} name={name} />}
        label={label}
      />
    </FormGroup>
  );
};
