import useSWR from "swr";
import intersection from "lodash/intersection";
import { authFetchPost } from "../utils/fetch";
import { GRAPH_API_URL, SPECIAL_DISPLAY_ADMIN_GROUPS, THEME_ADMIN_GROUPS } from "../config";
import { UserGroup } from "../api/users";
import { IPublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

const authFetchPostWithBody = async (url: string, authProvider: IPublicClientApplication) => {
  return authFetchPost<GraphApiResponse>(url, authProvider, {
    body: JSON.stringify({
      groupIds: [...SPECIAL_DISPLAY_ADMIN_GROUPS, ...THEME_ADMIN_GROUPS],
    }),
  });
};

interface GraphApiResponse {
  value: string[];
}
export function useUserGroups(): {
  userGroups: UserGroup[];
  error: boolean;
  loading: boolean;
} {
  const { instance: publicClientApplication } = useMsal();
  const url = `${GRAPH_API_URL}/me/checkMemberGroups`;
  const { data, error } = useSWR<GraphApiResponse>(url, () => authFetchPostWithBody(url, publicClientApplication));

  const userGroups = [];
  if (data?.value && intersection(data?.value, SPECIAL_DISPLAY_ADMIN_GROUPS).length > 0) {
    userGroups.push(UserGroup.SPECIAL_DISPLAY_ADMIN);
  }
  if (data?.value && intersection(data?.value, THEME_ADMIN_GROUPS).length > 0) {
    userGroups.push(UserGroup.THEME_ADMIN);
  }

  return { userGroups, error, loading: !error && !data };
}
