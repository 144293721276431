import { createTheme, responsiveFontSizes, ThemeOptions } from "@material-ui/core/styles";
import { fiFI } from "@material-ui/core/locale";

// A custom theme for this app
const theme: ThemeOptions = {
  palette: {
    common: { black: "#000000", white: "#ffffff" },
    background: { paper: "#ffffff", default: "#e5e5e5" },
    primary: {
      light: "#525252",
      main: "#2a2a2a",
      dark: "#000000",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#ff6b67",
      main: "#e3333c",
      dark: "#a90015",
      contrastText: "#ffffff",
    },
    error: { light: "#ff6b67", main: "#e3333c", dark: "#a90015", contrastText: "#ffffff" },
    text: {
      primary: "#000000",
      secondary: "#6d6d6d",
      disabled: "#6d6d6d",
      hint: "#e5e5e5",
    },
  },
  typography: {
    fontFamily: "Verdana",
    fontSize: 14,
    htmlFontSize: 10,
    h1: {
      fontSize: 36,
      fontWeight: "normal",
      textTransform: "uppercase",
    },
    h2: {
      fontSize: 26,
      textTransform: "uppercase",
      marginBottom: "0.5em",
    },
    h3: {
      fontSize: 26,
      marginBottom: "1em",
      marginTop: "1em",
    },
    h4: {
      fontSize: 16,
      fontWeight: "normal",
      textTransform: "uppercase",
      marginBottom: "0.5em",
      marginTop: "1em",
    },
    h5: {
      fontSize: 16,
      fontWeight: "bold",
      marginBottom: "0.5em",
      marginTop: "1em",
    },
  },
  overrides: {
    MuiTableCell: {
      head: {
        fontWeight: "bold",
        textTransform: "uppercase",
      },
      sizeSmall: {
        paddingLeft: 8,
        paddingRight: 12,
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
    MuiFormControl: {
      root: {
        margin: 0,
      },
    },
  },
};

export default responsiveFontSizes(createTheme(theme, fiFI));
