import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { LoginRequired } from "./pages/LoginRequired";
import Header from "./components/common/Header";
import { Footer } from "./components/common/Footer";
import Main from "./Main";
import { Drawer, makeStyles, Theme, createStyles, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { Omit } from "@material-ui/types";
import { useUserGroups } from "./hooks/useUserGroups";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { UserGroup } from "./api/users";
import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { NotFound } from "./pages/NotFound";

const SpecialDisplayIcon = () => (
  <div style={{ fontFamily: "Alko_VK_websymbolit", fontSize: 24, fontWeight: "bold" }}>D</div>
);

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  onClick?: () => void;
}

function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to, onClick } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "to">>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink} onClick={onClick}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      minWidth: "24rem",
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerPaper: {
      minWidth: "24rem",
    },
    drawerContainer: {
      overflow: "auto",
      paddingTop: theme.spacing(2) + 80,
      overflowX: "hidden",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(4, 2),
      maxWidth: "100vw",
    },
  }),
);

function handleLogin(loginInstance: IPublicClientApplication) {
  loginInstance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
}

function handleLogout(loginInstance: IPublicClientApplication) {
  loginInstance.logoutRedirect().catch((e) => {
    console.error(e);
  });
}

function App(): JSX.Element {
  const { instance: publicClientApplication } = useMsal();
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { userGroups } = useUserGroups();

  if (!publicClientApplication) {
    console.log("No pca! Verify configuration.");
    return <NotFound />;
  }

  const toggleDrawerOpen = (open?: boolean) => {
    setDrawerOpen(open || !drawerOpen);
  };

  const login = () => {
    handleLogin(publicClientApplication);
  };

  return (
    <Router>
      <Header
        activeAccount={publicClientApplication.getActiveAccount()}
        login={login}
        logout={() => handleLogout(publicClientApplication)}
        title="Esillepanot"
        drawerOpen={drawerOpen}
        toggleDrawerOpen={toggleDrawerOpen}
      />
      <div className={classes.root}>
        <Drawer
          anchor="left"
          variant="temporary"
          open={drawerOpen}
          onClose={() => toggleDrawerOpen(false)}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerContainer}>
            <List>
              {userGroups?.includes(UserGroup.SPECIAL_DISPLAY_ADMIN) && (
                <ListItemLink
                  to="/specialdisplays"
                  primary="Esillepanot"
                  icon={<SpecialDisplayIcon />}
                  onClick={() => toggleDrawerOpen(false)}
                />
              )}
              {userGroups?.includes(UserGroup.THEME_ADMIN) && (
                <ListItemLink
                  to="/themes"
                  primary="Teemat ja jaksot"
                  icon={<DateRangeIcon />}
                  onClick={() => toggleDrawerOpen(false)}
                />
              )}
            </List>
          </div>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <AuthenticatedTemplate>
            <Main activeAccount={publicClientApplication.getActiveAccount()} />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <LoginRequired />
          </UnauthenticatedTemplate>
        </main>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
