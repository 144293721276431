import { getApiUrl } from "../utils/request";
import { apiFetch } from "../utils/fetch";
import { ApiResponse } from "../utils/response";
import { getProductId } from "../utils/product";

export interface ProductId {
  id: string;
}

export interface MinimalProduct extends ProductId {
  name: string;
  volume: number;
  price: number;
  gtin: string | null;
  producer: string | null;
  agentName: string | null;
  abv: number | null;
  basket: string | null;
  mainGroupName: string[];
  productGroupName: string[];
  countryName: string | null;
}

export interface Product extends ProductId {
  name: string;
  volume: number;
  price: number;
  gtin: string | null;
  startOfSale: string | null;
  parentProductGroupId: string | null;
  labelDescription: string | null;
  moreInfo: string | null;
  moreInfo2: string | null;
  maturationDescription: string | null;
  producer: string | null;
  hideListing: boolean | null;
  specialAccessory: boolean | null;
  deposit: number | null;
  simplePackageSize: number | null;
  pricePerLitre: number | null;
  abv: number | null;
  vintage: number | null;
  agentUrl: string | null;
  sugarPerLitre: number | null;
  extractPerLitre: number | null;
  acidsPerLitre: number | null;
  energyPerDlKcal: number | null;
  energyPerDlKj: number | null;
  beerWortPlato: number | null;
  beerBitternessEbu: number | null;
  beerColourEbc: string | null;
  taste: string | null;
  rawMaterialDescription: string | null;
  preparationDescription: string | null;
  producerDescription: string | null;
  additionalDescription: string | null;
  regionDescription: string | null;
  basket: string | null;
  stores: string | null;
  mainGroupId: string[];
  mainGroupName: string[];
  productGroupId: string[];
  productGroupName: string[];
  additionalProductGroupId: string[];
  additionalProductGroupName: string[];
  beerBitternessId: string[];
  beerBitternessName: string[];
  beerStyleId: string[];
  beerStyleName: string[];
  beerSubstyleId: string[];
  beerSubstyleName: string[];
  bodyId: string[];
  bodyName: string[];
  certificateId: string[];
  certificateName: string[];
  ciderTypeId: string[];
  ciderTypeName: string[];
  closureId: string[];
  closureName: string[];
  ethicalCertificateId: string[];
  ethicalCertificateName: string[];
  foodSymbolId: string[];
  foodSymbolName: string[];
  grapeId: string[];
  grapeName: string[];
  longdrinkTypeId: string[];
  longdrinkTypeName: string[];
  newOrDelistedId: string[];
  newOrDelistedName: string[];
  packageTypeId: string[];
  packageTypeName: string[];
  packageSizeId: string[];
  packageSizeName: string[];
  priceRangeId: string[];
  priceRangeName: string[];
  rumTypeId: string[];
  rumTypeName: string[];
  seasonalProductId: string[];
  seasonalProductName: string[];
  selectionTypeId: string[];
  selectionTypeName: string[];
  sweetnessId: string[];
  sweetnessName: string[];
  tasteStyleId: string[];
  tasteStyleName: string[];
  tasteStyleCiderId: string[];
  tasteStyleCiderName: string[];
  tasteStyleLongDrinkId: string[];
  tasteStyleLongDrinkName: string[];
  tasteStyleStrongId: string[];
  tasteStyleStrongName: string[];
  themeId: string | null;
  themeName: string | null;
  themeStartDate: string | null;
  themeStartDateTs: string | null;
  themeEndDate: string | null;
  themeEndDateTs: string | null;
  themeArgument: string | null;
  wineAcidityId: string[];
  wineAcidityName: string[];
  wineTanninsId: string[];
  wineTanninsName: string[];
  countryId: string | null;
  countryName: string | null;
  regionId: string | null;
  regionName: string | null;
  colour: string | null;
  maturity: string | null;
  aroma: string | null;
  durability: string | null;
  producerWww: string | null;
  shelflifeDescription: string | null;
  distributor: string | null;
  startOfSaleTs: number | null;
  status: string | null;
  statusId: string | null;
  endOfSale: string | null;
  endOfSaleTs: string | null;
  additionalDescription2: string | null;
  grapePercentage: string[];
  dolly: string[];
  presentationGroup: {
    id: string | null;
    name: string | null;
  };
  productInformationDate: string | null;
  productInformationDateTs: number | null;
  selectionProfile: string[];
  delistedReason: string | null;
  multiPack: boolean;
  batchInfo: string | null;
  changedPrice: string | null;
  priceChangedDate: string | null;
  priceChangedDateTs: string | null;
  startOfSaleWeb: string | null;
  startOfSaleWebTs: string | null;
  dollyDistributor: string | null;
  tasteStyleRtdId: string[];
  tasteStyleRtdName: string[];
  tasteStyleSparklingId: string[];
  tasteStyleSparklingName: string[];
  sparklingGroupId: string[];
  sparklingGroupName: string[];
  tasteStyleWhiskyId: string[];
  tasteStyleWhiskyName: string[];
  whiskySmokinessId: string[];
  whiskySmokinessName: string[];
  marketingInformation: string | null;
  marketingVideo: string[];
  marketingURL: [
    {
      url: string | null;
    },
  ];
  randomPoint: {
    type: string | null;
    coordinates: number[];
  };
  onlineAvailabilityDatetime: string | null;
  onlineAvailabilityDatetimeTs: number | null;
  onlineAvailability: boolean;
  noInternet: number | null;
  agentId: number | null;
  agentName: string | null;
  distributorId: string | null;
  deliveryStores: string[];
}

export async function getProduct(id: string): Promise<ApiResponse<Product>> {
  return await apiFetch<Product>(getApiUrl(`/v1/products/${getProductId(id)}`));
}
