import React from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles, TableRow, TableCell, IconButton, Collapse, Box, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import SearchIcon from "@material-ui/icons/Search";
import { Store } from "../../api/stores";
import { ThemeCampaign, Implementation, TextField } from "../../api/themes";
import { formatDate } from "../../utils/date";
import { useSavedTheme } from "../../hooks/useSavedTheme";
import { useSavedSpecialDisplayType } from "../../hooks/useSavedSpecialDisplayType";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});
const textOrLink = (cid: number, text: string) => {
  const trimmed = text.trimStart();
  if (trimmed.startsWith("http://") || trimmed.startsWith("https://")) {
    const url = trimmed.split(" ")[0];
    return (
      <p>
        Linkki: <a href={url}>{url}</a>
      </p>
    );
  }
  const output: JSX.Element[] = [];
  trimmed.split("\n").forEach((s, i) => {
    const st = s.trimStart();
    output.push(
      <React.Fragment key={`${cid}-tx-${i}`}>
        {st.padStart(s.length, "\u2001")}
        <br />
      </React.Fragment>,
    );
  });
  return <Typography variant="body1">{output}</Typography>;
};

export const ThemeCampaignRow = ({ store, campaign }: { store?: Store; campaign: ThemeCampaign }): JSX.Element => {
  const classes = useRowStyles();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const { setThemeId } = useSavedTheme();
  const { setSpecialDisplayTypeId } = useSavedSpecialDisplayType();

  const handleOpenThemeDisplays = (campaignId: number) => {
    setThemeId(campaignId);
    setSpecialDisplayTypeId(null);
    navigate("/specialdisplays");
  };

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell size="small" padding="checkbox">
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Box display="flex" flexDirection="row" alignItems="center">
            {`${store ? `${store.name}: ` : ""}`}
            {campaign.name}
            {campaign.implementations.length > 0 && (
              <Box px={1} title="Myymälällä merkintöjä">
                <InfoOutlinedIcon fontSize="small" />
              </Box>
            )}
          </Box>
        </TableCell>
        <TableCell>
          {formatDate(campaign.startDate)} - {formatDate(campaign.endDate)}
        </TableCell>
        <TableCell size="small" padding="checkbox">
          <IconButton onClick={() => handleOpenThemeDisplays(campaign.id)}>
            <SearchIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, maxWidth: "90vw" }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box m={1}>
              {campaign.textFields?.map((tf: TextField, i: number) => (
                <Box key={`${campaign.id}-tf-${i}`}>
                  <Typography variant="h4">{tf.title}</Typography>
                  {textOrLink(campaign.id, tf.text)}
                </Box>
              ))}
              {campaign.implementations.length > 0 && <Typography variant="h4">Myymälän merkinnät</Typography>}
              {campaign.implementations.map((imp: Implementation, i: number) => (
                <Box key={`${campaign.id}-imp-${i}`}>{textOrLink(campaign.id, imp.text)}</Box>
              ))}
              <Box py={1}>
                <Typography variant="body2">
                  Tarkemmat tiedot <a href="https://alko.vuosikello.fi">Vuosikellossa</a>
                </Typography>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} />
      </TableRow>
    </>
  );
};
