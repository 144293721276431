import React from "react";
import { Box, Button, Modal, Typography } from "@material-ui/core";
import { SpecialDisplay } from "../../api/specialDisplays";
import { formatDate } from "../../utils/date";

interface Props {
  modalOpen: boolean;
  handleModalClose: () => void;
  latestSpecialDisplay?: SpecialDisplay;
}

export const TooFrequentModal = ({ modalOpen, handleModalClose, latestSpecialDisplay }: Props): JSX.Element => {
  return (
    <Modal open={modalOpen} onClose={handleModalClose}>
      <Box
        style={{
          position: "absolute",
          top: "40%",
          left: "60%",
          transform: "translate(-50%, -50%)",
          width: 600,
          backgroundColor: "#EEE",
          border: "1px solid #000",
          boxShadow: "24",
        }}
      >
        <Typography variant="h6" component="h2" style={{ padding: "6px 12px" }}>
          Huomio
        </Typography>
        {!latestSpecialDisplay ? (
          <Typography style={{ padding: "4px 13px" }}>Virhe</Typography>
        ) : (
          <Box>
            <Typography style={{ padding: "4px 13px" }}>
              Tuote ({latestSpecialDisplay.product.id}) on ollut esillä myymälässä viimeisen 12kk aikana.
            </Typography>
            <Typography style={{ padding: "4px 13px" }}>
              Viimeksi jaksolla "{latestSpecialDisplay.period.name} ({formatDate(latestSpecialDisplay.period.startDate)}{" "}
              - {formatDate(latestSpecialDisplay.period.endDate)}
              )"
            </Typography>
            {latestSpecialDisplay.specialDisplayType && (
              <Typography style={{ padding: "4px 13px" }}>
                Esillepanotyypissä {latestSpecialDisplay.specialDisplayType.name.toLowerCase()}.
              </Typography>
            )}
          </Box>
        )}
        <Button onClick={handleModalClose} style={{ padding: "6px 12px", fontSize: "10px" }}>
          Sulje
        </Button>
      </Box>
    </Modal>
  );
};
