import React from "react";
import { Box, Grid, Typography, IconButton, createStyles, makeStyles, Theme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { isAfter, isBefore } from "date-fns";
import { SpecialDisplayFormData, SpecialDisplay } from "../../api/specialDisplays";
import { SpecialDisplayForm } from "./SpecialDisplayForm";
import { cloneDeep } from "lodash";
import StopDisplayButtonWithDialog from "./StopDisplayButtonWithDialog";
import DeleteDisplayButtonWithDialog from "./DeleteDisplayButtonWithDialog";
import { formatDate, localDate } from "../../utils/date";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      marginTop: 0,
    },
  }),
);

interface Props {
  item: SpecialDisplay;
  onSave: (id: string, values: SpecialDisplayFormData) => void;
  onDelete: (id: string) => void;
  onCancel: () => void;
}
export const EditSpecialDisplayDrawer = ({ item, onSave, onCancel, onDelete }: Props): JSX.Element => {
  const classes = useStyles();
  const isCurrentDisplay =
    isAfter(localDate(), localDate(item.startDate)) && isBefore(localDate(), localDate(item.endDate));
  const isPastDisplay = isAfter(localDate(), localDate(item.endDate));
  const isFutureDisplay = isBefore(localDate(), localDate(item.startDate));

  const handleEndSpecialDisplay = (item: SpecialDisplay, comment: string) => {
    const copy: SpecialDisplayFormData = {
      ...cloneDeep(item),
      indefinitely: false,
      endDate: new Date(),
      comment: comment,
      year: item.period.year,
    };
    onSave(item.id, copy);
  };

  const handleDeleteSpecialDisplay = (item: SpecialDisplay) => {
    onDelete(item.id);
  };

  if (!item) return <Box py={4}>Virhe</Box>;

  return (
    <Box>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box display="flex" alignItems="center">
            <Typography variant="h2" style={{ padding: 0, margin: 0 }}>
              {isPastDisplay ? "Esillepanon tiedot" : "Muokkaa esillepanoa"}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box mr={-2}>
            <IconButton onClick={onCancel} title="Sulje">
              <CloseIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      <Box py={2}>
        <Grid container justifyContent="flex-end">
          <Grid item xs={4}>
            <Typography variant="h5" className={classes.heading}>
              Myymälä
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              {item.store.id} {item.store.name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h5" className={classes.heading}>
              Jakso
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              {item.period.name} ({formatDate(item.period.startDate)} - {formatDate(item.period.endDate)})
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h5" className={classes.heading}>
              {"Esillepano\u00ADteema"}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              {item.theme.id
                ? `${item.theme.name} (${formatDate(item.theme.startDate)} - ${formatDate(item.theme.endDate)})`
                : "-"}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box pt={2}>
        <SpecialDisplayForm
          item={item}
          product={item.product}
          store={item.store}
          period={item.period}
          year={item.period.year}
          theme={item.theme}
          onSave={(formData) => onSave(item.id, formData)}
          onCancel={onCancel}
          actions={
            <div>
              {isCurrentDisplay && isBefore(new Date(), new Date(item.endDate)) && (
                <StopDisplayButtonWithDialog item={item} onConfirm={handleEndSpecialDisplay} />
              )}
              {isFutureDisplay && <DeleteDisplayButtonWithDialog item={item} onConfirm={handleDeleteSpecialDisplay} />}
            </div>
          }
        />
      </Box>
    </Box>
  );
};
