import React, { useEffect, useState } from "react";
import { TablePage } from "../components/specialDisplays/TablePage";
import { HeadCell } from "../components/common/table/TableHead";
import { OrderDirection } from "../utils/order";
import { useSavedStore } from "../hooks/useSavedStore";
import { useSavedTheme } from "../hooks/useSavedTheme";
import { useSavedPeriod } from "../hooks/useSavedPeriod";
import Box from "@material-ui/core/Box";
import startOfYear from "date-fns/startOfYear";
import endOfYear from "date-fns/endOfYear";
import { useSavedYear } from "../hooks/useSavedYear";
import { useSavedSpecialDisplayType } from "../hooks/useSavedSpecialDisplayType";
import { useSavedDisplaySubType } from "../hooks/useSavedDisplaySubType";
import { formatISODate } from "../utils/date";
import { useSavedActiveOnly } from "../hooks/useSavedActiveOnly";
import { useSavedProductDistributor } from "../hooks/useSavedProductDistributor";
import { useSavedDisplayStatus } from "../hooks/useSavedDisplayStatus";

const headCells: HeadCell[] = [
  { id: "selectAll", numeric: false, sortable: false, disablePadding: false, label: "", isCheckbox: true },
  { id: "product.id", numeric: true, sortable: true, disablePadding: false, label: "Tuote" },
  { id: "product.name", numeric: false, sortable: true, disablePadding: false, label: "Tuotteen nimi" },
  {
    id: "product.mainGroupName[0]",
    numeric: false,
    sortable: true,
    disablePadding: false,
    label: "Tuoteryhmä",
    mdDown: true,
  },
  { id: "product.price", numeric: true, sortable: true, disablePadding: false, label: "Hinta", mdDown: true },
  { id: "product.basket", numeric: false, sortable: true, disablePadding: false, label: "Kori", mdDown: true },
  { id: "product.countryName", numeric: false, sortable: true, disablePadding: false, label: "Maa", mdDown: true },
  {
    id: "product.agentName",
    numeric: false,
    sortable: true,
    disablePadding: true,
    label: "Toimittaja",
    lgDown: true,
  },
  {
    id: "product.distributorId",
    numeric: false,
    sortable: true,
    disablePadding: false,
    label: "Varasto",
    smDown: true,
  },
  {
    id: "presentationType",
    numeric: false,
    sortable: true,
    disablePadding: false,
    label: "Esillepano\u00ADtyyppi",
    smDown: true,
  },
  { id: "amount", numeric: false, sortable: true, disablePadding: false, label: "Määrä", smDown: true },
  { id: "startDate", numeric: false, sortable: true, disablePadding: false, label: "Jakso", smDown: true },
];

export const ListSpecialDisplays = (): JSX.Element => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderDirection, setOrderDirection] = useState<OrderDirection>("asc");
  const [orderBy, setOrderBy] = useState<string>("product.id");

  const { year } = useSavedYear();
  const { storeId } = useSavedStore();
  const { themeId } = useSavedTheme();
  const { period } = useSavedPeriod();
  const { specialDisplayTypeId } = useSavedSpecialDisplayType();
  const { displaySubTypeId } = useSavedDisplaySubType();
  const { productDistributorId } = useSavedProductDistributor();
  const { activeOnly } = useSavedActiveOnly();
  const { displayStatusId } = useSavedDisplayStatus();

  useEffect(() => {
    setPage(0);
  }, [year, storeId, themeId, period, specialDisplayTypeId, productDistributorId, activeOnly, displayStatusId]);

  const queryFilters: Record<string, number | string | boolean> = {};
  if (storeId) queryFilters["store.id"] = storeId;
  if (activeOnly) queryFilters["activeOnly"] = true;
  if (themeId) {
    queryFilters["theme.id"] = themeId;
  } else if (!activeOnly) {
    if (period) {
      queryFilters["startDate"] = formatISODate(period.startDate);
      queryFilters["endDate"] = formatISODate(period.endDate);
    } else {
      const yearDate = new Date(year, 0);
      queryFilters["startDate"] = formatISODate(startOfYear(yearDate));
      queryFilters["endDate"] = formatISODate(endOfYear(yearDate));
    }
  }
  if (specialDisplayTypeId) queryFilters["specialDisplayType.id"] = specialDisplayTypeId;
  if (displaySubTypeId) queryFilters["displaySubType.id"] = displaySubTypeId;
  if (productDistributorId) queryFilters["product.distributorId"] = productDistributorId;
  if (displayStatusId) queryFilters["displayStatus.id"] = displayStatusId;

  return (
    <Box p={{ xs: 0, sm: 0, md: 2 }}>
      <TablePage
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        orderDirection={orderDirection}
        setOrderDirection={setOrderDirection}
        filters={queryFilters}
        headCells={headCells}
      />
    </Box>
  );
};
