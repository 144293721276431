import queryString from "query-string";
import { API_URL, API_KEY } from "../config";
import axios, { AxiosError } from "axios";
import { ApiResponse } from "./response";
import isEmpty from "lodash/isEmpty";
import { loginRequest } from "../authConfig";
import { AuthError, IPublicClientApplication } from "@azure/msal-browser";

// Typeguards for Errors to avoid linting errors
export function getError(err: Error | AxiosError | AuthError | any): Error | AxiosError | AuthError {
  if (axios.isAxiosError(err)) return err as AxiosError;
  if (err instanceof AuthError || (err && err.errorCode)) return err as AuthError;
  return err as Error;
}

export const getApiHeaders = (): Record<string, string> => {
  return {
    "Content-Type": "application/json",
    "x-functions-key": API_KEY,
  };
};

export const getApiHeadersWithUser = async (
  authProvider: IPublicClientApplication,
  initHeaders?: Headers,
): Promise<Headers> => {
  const headers = new Headers(initHeaders);
  let accessToken;
  let username;
  try {
    const account = authProvider.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await authProvider.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });

    accessToken = response.accessToken;
    username = account.username;
  } catch (err) {
    const error = getError(err) as AuthError;
    if (
      error.errorCode === "consent_required" ||
      error.errorCode === "interaction_required" ||
      error.errorCode === "login_required"
    ) {
      accessToken = await authProvider.acquireTokenRedirect(loginRequest);
      username = authProvider.getActiveAccount()?.username;
    }
  }

  headers.set("Content-Type", "application/json");
  headers.set("x-functions-key", API_KEY);
  if (accessToken) {
    headers.set("Authorization", `Bearer ${accessToken}`);
  }
  if (username) headers.set("x-alko-user", username);
  return headers;
};

export const getAuthHeaders = async (
  authProvider: IPublicClientApplication,
  initHeaders?: Headers,
): Promise<Headers> => {
  const account = authProvider.getActiveAccount();
  if (!account) {
    throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }

  const headers = new Headers(initHeaders);
  let accessToken;
  try {
    const response = await authProvider.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });

    accessToken = response.accessToken;
  } catch (err) {
    const error = getError(err) as AuthError;
    if (
      error.errorCode === "consent_required" ||
      error.errorCode === "interaction_required" ||
      error.errorCode === "login_required"
    ) {
      accessToken = await authProvider.acquireTokenRedirect(loginRequest);
    }
  }

  headers.set("Content-Type", "application/json");
  headers.set("Authorization", `Bearer ${accessToken}`);
  return headers;
};

export interface Filter {
  field: string;
  value: string | string[];
}

export interface Query {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const getApiUrl = (path: string, query?: Query): string => {
  let filters: string[] = [];
  if (query?.filters && !isEmpty(query?.filters)) {
    filters = Object.keys(query?.filters);
  }
  const url = queryString.stringifyUrl(
    {
      url: `${API_URL}${path}`,
      query: { ...query, filters, ...query?.filters },
    },
    { skipNull: true, skipEmptyString: true, arrayFormat: "comma" },
  );

  return url;
};

const apiResponseJson = <T>(responseText: string) => {
  const json = JSON.parse(responseText, (key, value) => {
    if (value && (key === "timestamp" || key.endsWith("Date"))) {
      return new Date(value);
    }
    return value;
  });
  return json as ApiResponse<T>;
};

export const getFromApi = async <T>(path: string, query?: Query): Promise<ApiResponse<T>> => {
  const headers = getApiHeaders();
  const response = await fetch(getApiUrl(path, query), { headers });
  if (!response.ok) {
    const { error } = await response.json();
    throw new Error(error);
  }
  return apiResponseJson<T>(await response.text());
};

export const postToApi = async <T>(
  authProvider: IPublicClientApplication,
  path: string,
  body: Record<string, unknown>,
): Promise<ApiResponse<T>> => {
  const headers = await getApiHeadersWithUser(authProvider);
  const response = await fetch(getApiUrl(path), {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    const { error } = await response.json();
    throw new Error(error);
  }
  return apiResponseJson<T>(await response.text());
};

export const putToApi = async <T>(
  authProvider: IPublicClientApplication,
  path: string,
  body: Record<string, unknown>,
): Promise<ApiResponse<T>> => {
  const headers = await getApiHeadersWithUser(authProvider);
  const response = await fetch(getApiUrl(path), {
    method: "PUT",
    headers,
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    const { error } = await response.json();
    throw new Error(error);
  }
  return apiResponseJson<T>(await response.text());
};

export const deleteToApi = async <T>(authProvider: IPublicClientApplication, path: string): Promise<ApiResponse<T>> => {
  const headers = await getApiHeadersWithUser(authProvider);
  const response = await fetch(getApiUrl(path), {
    method: "DELETE",
    headers,
  });
  if (!response.ok) {
    const { error } = await response.json();
    throw new Error(error);
  }
  return apiResponseJson<T>(await response.text());
};
