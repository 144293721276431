import { DisplaySubType, dollySubTypes, coldCabinetSubTypes } from "../api/specialDisplays";
import { useLocalStorage } from "./useLocalStorage";

export const useSavedDisplaySubType = (): {
  displaySubTypeId?: string | null;
  setDisplaySubTypeId: (value?: string | null) => void;
  displaySubType?: DisplaySubType;
} => {
  const { displaySubTypeId, setDisplaySubTypeId } = useLocalStorage();
  const allSubTypes = [...coldCabinetSubTypes, ...dollySubTypes];
  const displaySubType = allSubTypes.find((s) => s.id === displaySubTypeId);
  return { displaySubTypeId, setDisplaySubTypeId, displaySubType };
};
