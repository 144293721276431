import { SpecialDisplayType, specialDisplayTypes } from "../api/specialDisplays";
import { useLocalStorage } from "./useLocalStorage";

export const useSavedSpecialDisplayType = (): {
  specialDisplayTypeId?: string | null;
  setSpecialDisplayTypeId: (value?: string | null) => void;
  specialDisplayType?: SpecialDisplayType;
} => {
  const { specialDisplayTypeId, setSpecialDisplayTypeId } = useLocalStorage();
  const specialDisplayType = specialDisplayTypes.find((s) => s.id === specialDisplayTypeId);
  return { specialDisplayTypeId, setSpecialDisplayTypeId, specialDisplayType };
};
