import { IPublicClientApplication } from "@azure/msal-browser";
import fetch from "isomorphic-unfetch";
import { getApiHeaders, getApiHeadersWithUser, getAuthHeaders } from "./request";
import { ApiResponse } from "./response";

const responseJson = <T>(responseText: string) => {
  const json = JSON.parse(responseText, (key, value) => {
    if (value && (key === "timestamp" || key.endsWith("Date"))) {
      return new Date(value);
    }
    return value;
  });
  return json as T;
};

export async function plainFetch<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
  const dataText = await fetch(input, init).then((res) => res.text());
  return responseJson<T>(dataText);
}

export async function apiFetch<T>(input: RequestInfo, init?: RequestInit): Promise<ApiResponse<T>> {
  const headers = getApiHeaders();
  const dataText = await fetch(input, { ...init, headers: { ...init?.headers, ...headers } }).then((res) => res.text());
  const data = responseJson<ApiResponse<T>>(dataText);
  if (data.error) throw new Error(data.error);
  return data;
}

export async function apiFetchPost<T>(
  input: RequestInfo,
  authProvider: IPublicClientApplication,
  init?: RequestInit,
): Promise<ApiResponse<T>> {
  const headers = await getApiHeadersWithUser(authProvider, new Headers(init?.headers));
  const dataText = await fetch(input, { ...init, headers }).then((res) => res.text());
  const data = responseJson<ApiResponse<T>>(dataText);
  if (data.error) throw new Error(data.error);
  return data;
}

export async function authFetchPost<T>(
  input: RequestInfo,
  authProvider: IPublicClientApplication,
  init?: RequestInit,
): Promise<T> {
  const headers = await getAuthHeaders(authProvider, new Headers(init?.headers));
  const dataText = await fetch(input, { ...init, method: "POST", headers }).then((res) => res.text());
  return responseJson<T>(dataText);
}

export default apiFetch;
