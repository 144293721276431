import React from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { StoreSelect } from "../filter/StoreSelect";
import { YearSelect } from "../filter/YearSelect";
import { PeriodSelect } from "../filter/PeriodSelect";
import { useSavedPeriod } from "../../hooks/useSavedPeriod";
import { useSavedYear } from "../../hooks/useSavedYear";
import { useSavedStore } from "../../hooks/useSavedStore";

export const FilterToolbar = (): JSX.Element => {
  const { storeId, setStoreId } = useSavedStore();
  const { year, setYear } = useSavedYear();
  const { periodId, setPeriodId, currentPeriod } = useSavedPeriod();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  const changeYear = (year: number) => {
    setYear(year);
    setPeriodId(null);
  };

  return (
    <Box pb={2}>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <StoreSelect storeId={storeId} setStoreId={setStoreId} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container direction="row" spacing={1} wrap="nowrap" alignItems="center">
            <Grid item>
              <YearSelect year={year} setYear={changeYear} small={small} />
            </Grid>
            <Grid item xs>
              <PeriodSelect
                year={year}
                setYear={changeYear}
                periodId={periodId}
                setPeriodId={setPeriodId}
                currentPeriodId={currentPeriod?.id}
                small={small}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
