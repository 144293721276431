import useSWR from "swr";
import { StoreCampaign, ThemeCampaign } from "../api/themes";
import { getApiUrl } from "../utils/request";
import { ApiResponse } from "../utils/response";
import { useLocalStorage } from "./useLocalStorage";
import fetch from "../utils/fetch";

export const useSavedTheme = (
  year: number = new Date().getFullYear(),
): {
  themeId: number | null;
  setThemeId: (value: number | null) => void;
  theme?: ThemeCampaign | StoreCampaign | null;
  error: boolean;
} => {
  const { themeId, setThemeId } = useLocalStorage();
  const { data, error } = useSWR<ApiResponse<ThemeCampaign | StoreCampaign | null>>(
    themeId
      ? getApiUrl(`/v1/themes/${themeId}`, {
          year,
        })
      : null,
    fetch,
  );

  return {
    themeId: themeId || null,
    setThemeId,
    theme: data?.data,
    error,
  };
};
