import { ProductDistributor, productDistributors } from "../api/specialDisplays";
import { useLocalStorage } from "./useLocalStorage";

export const useSavedProductDistributor = (): {
  productDistributorId?: string | null;
  setProductDistributorId: (value?: string | null) => void;
  productDistributor?: ProductDistributor;
} => {
  const { productDistributorId, setProductDistributorId } = useLocalStorage();
  const productDistributor = productDistributors.find((s) => s.id === productDistributorId);
  return { productDistributorId, setProductDistributorId, productDistributor };
};
