import React, { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { SpecialDisplay } from "../../api/specialDisplays";

interface Props {
  item: SpecialDisplay;
  onConfirm: (item: SpecialDisplay) => void;
}
const DeleteDisplayButtonWithDialog = ({ item, onConfirm }: Props): JSX.Element => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteDialogOpen = () => setDeleteDialogOpen(true);
  const handleDeleteDialogClose = () => setDeleteDialogOpen(false);

  return (
    <>
      <Button variant="contained" color="secondary" onClick={handleDeleteDialogOpen}>
        Poista esillepano
      </Button>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Poistetaanko esillepano?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tuotteen {item.product.name} esillepano poistetaan kokonaan.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Peruuta
          </Button>
          <Button onClick={() => onConfirm(item)} color="primary" variant="contained" autoFocus>
            Poista tuote esillepanosta
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteDisplayButtonWithDialog;
