import useSWR from "swr";
import { getApiUrl } from "../utils/request";
import fetch from "../utils/fetch";
import { ApiResponse } from "../utils/response";
import { Period, ThemePeriodsResponse } from "../api/themes";

export function useThemePeriods(): {
  periods: ThemePeriodsResponse[];
  periodsMap: Map<string | null, Period>;
  findPeriodForDate: (date: Date) => Period | undefined;
  currentPeriod?: Period;
  isLoading: boolean;
  isError: boolean;
} {
  const { data, error } = useSWR<ApiResponse<ThemePeriodsResponse[]>>(getApiUrl("/v1/themeperiods"), fetch);

  const today = new Date();
  const currentPeriod = data?.data
    ?.find((p) => p.year === today.getFullYear())
    ?.periods.find((p) => p.startDate <= today && today <= p.endDate);

  const periodsMap = new Map<string | null, Period>();
  data?.data.forEach((py) =>
    py.periods.forEach((p) => {
      periodsMap.set(p.id, p);
    }),
  );
  if (currentPeriod) periodsMap.set(null, currentPeriod);

  return {
    periods: data?.data || [],
    periodsMap,
    findPeriodForDate: (date: Date = new Date()) =>
      data?.data
        ?.find((p) => p.year === date.getFullYear())
        ?.periods.find((p) => p.startDate <= date && date <= p.endDate),
    currentPeriod,
    isLoading: !error && !data,
    isError: error,
  };
}
