import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import upperFirst from "lodash/upperFirst";
import React, { useState } from "react";

import useSWR from "swr";
import { HeadCell, TableHead } from "../common/table/TableHead";
import { formatEur } from "../../utils/currency";
import fetch from "../../utils/fetch";
import { OrderDirection } from "../../utils/order";
import { getApiUrl } from "../../utils/request";
import { ApiResponse } from "../../utils/response";
import { TableToolbar } from "../common/table/TableToolbar";
import { FilterToolbar } from "./FilterToolbar";
import {
  SpecialDisplay,
  createSpecialDisplay,
  updateSpecialDisplay,
  deleteSpecialDisplay,
  SpecialDisplayFormData,
  units,
  PackageType,
  SpecialDisplayTypeId,
  displayStatuses,
  FindSpecialDisplaysParams,
} from "../../api/specialDisplays";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import PrintOutlineIcon from "@material-ui/icons/PrintOutlined";
import { CreateSpecialDisplayDrawer } from "./CreateSpecialDisplayDrawer";
import { Checkbox, Drawer, Hidden } from "@material-ui/core";
import { EditSpecialDisplayDrawer } from "./EditSpecialDisplayDrawer";
import { useSavedStore } from "../../hooks/useSavedStore";
import { formatDatePeriod, localDate } from "../../utils/date";
import { useThemes } from "../../hooks/useThemes";
import isAfter from "date-fns/isAfter";
import cloneDeep from "lodash/cloneDeep";
import { ThemeCampaign } from "../../api/themes";
import { useSavedYear } from "../../hooks/useSavedYear";
import { useSavedTheme } from "../../hooks/useSavedTheme";
import { useSavedPeriod } from "../../hooks/useSavedPeriod";
import { useThemePeriods } from "../../hooks/useThemePeriods";
import startOfYear from "date-fns/startOfYear";
import endOfYear from "date-fns/endOfYear";
import { postFormToUrl } from "../../utils/postFormToUrl";
import { useMsal } from "@azure/msal-react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  }),
);

interface Props {
  page: number;
  rowsPerPage: number;
  orderBy: string;
  setOrderBy?: (orderBy: string) => void;
  orderDirection: OrderDirection;
  setOrderDirection?: (orderDirection: OrderDirection) => void;
  filters: Record<string, number | string | boolean>;
  headCells: HeadCell[];
  hidden?: boolean;
  setPage?: (page: number) => void;
  setRowsPerPage?: (rowsPerPage: number) => void;
}

export const TablePage = ({
  page,
  rowsPerPage,
  orderBy,
  setOrderBy = () => null,
  orderDirection,
  setOrderDirection = () => null,
  filters,
  headCells,
  hidden = false,
  setPage = () => null,
  setRowsPerPage = () => null,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { instance: publicClientApplication } = useMsal();

  const {
    data,
    error: fetchError,
    mutate,
  } = useSWR<ApiResponse<SpecialDisplay[]>>(
    getApiUrl("/v1/specialdisplays", {
      page,
      pageSize: rowsPerPage,
      orderBy,
      orderDirection,
      filters,
    } as FindSpecialDisplaysParams),
    fetch,
  );

  const items = data?.data || [];
  const count = data?.count || 1;
  const error = data?.error;
  const { storeId } = useSavedStore();
  const { periodId } = useSavedPeriod();
  const { periodsMap, isLoading: periodsLoading } = useThemePeriods();
  const period = periodId ? periodsMap.get(periodId) : null;
  const { themeId } = useSavedTheme();
  const { year } = useSavedYear();
  const { campaigns, isLoading: themesLoading } = useThemes(
    year || undefined,
    storeId || undefined,
    period?.startDate || startOfYear(new Date(year, 0)),
    period?.endDate || endOfYear(new Date(year, 0)),
  );
  const loading = (!error && !fetchError && !data) || periodsLoading || themesLoading;
  const selectedTheme = campaigns?.find((c: ThemeCampaign) => c.id === themeId);

  const isPastTheme = selectedTheme ? isAfter(localDate(), localDate(selectedTheme.endDate)) : false;
  const isPastPeriod = period ? isAfter(localDate(), localDate(period.endDate)) : false;
  const isAddProductDisabled = !storeId || isPastPeriod || isPastTheme;

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [editItem, setEditItem] = useState<SpecialDisplay>();

  const [selected, setSelected] = useState<string[]>([]);

  const openDrawerForCreate = () => {
    setEditItem(undefined);
    setDrawerOpen(true);
  };

  const getSelectedProductIds = () => {
    let selectedProductIds: string[] = [];

    selected.forEach((specialDisplayId: string, index: number) => {
      const productId = items.find((i) => i.id === specialDisplayId)?.product?.id;

      if (productId) {
        const selectedIndex = selectedProductIds.indexOf(productId);

        if (selectedIndex === -1) {
          selectedProductIds = selectedProductIds.concat(productId);
        }
      }
    });

    return selectedProductIds;
  };

  const isPrintDisabled = !(getSelectedProductIds()?.length > 0);

  const printSelectedProducts = () => {
    const printSelected = getSelectedProductIds();

    if (printSelected.length === 0) {
      return;
    }
    if (printSelected.length >= 100) {
      return;
    }

    const payload: Record<string, string[]> = {};
    payload["productnumber"] = printSelected;

    postFormToUrl(process.env.REACT_APP_PRINTING_URL, payload);
  };

  const openDrawerForEdit = (item: SpecialDisplay) => {
    const tzItem = cloneDeep(item);
    tzItem.startDate = localDate(item.startDate);
    tzItem.endDate = localDate(item.endDate);
    setEditItem(tzItem);
    setDrawerOpen(true);
  };

  const handleRequestSort = (_: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isAllSelected = () => {
    const allSelectedWithPageCount = selected && selected.length > 0 && selected.length === items.length;

    // also should check that are same ids still selected
    const stillEveryItem = selected.every((itemId) => items.find((i) => i.id === itemId));

    return allSelectedWithPageCount && stillEveryItem;
  };

  const handleSelectAll = () => {
    // is all selected
    if (isAllSelected()) {
      // remove selection
      setSelected([]);
    } else {
      // select all
      const itemIds = items.map((sp) => sp.id);
      setSelected(itemIds);
    }
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const handleCreate = async (formData: SpecialDisplayFormData) => {
    mutate(async (items: ApiResponse<SpecialDisplay[]> | undefined) => {
      const { data: createdItem } = await createSpecialDisplay(publicClientApplication, formData);
      const returnData = cloneDeep(items?.data) || [];
      returnData.push(createdItem);
      return { data: returnData };
    });
    closeDrawer();
  };

  const handleEdit = async (id: string, formData: SpecialDisplayFormData) => {
    mutate(async (items: ApiResponse<SpecialDisplay[]> | undefined) => {
      const { data: updated } = await updateSpecialDisplay(publicClientApplication, id, formData);
      return { data: items?.data.map((s) => (s.id === updated.id ? updated : s)) || [] };
    });
    closeDrawer();
  };

  const handleDelete = async (id: string) => {
    mutate(async (items: ApiResponse<SpecialDisplay[]> | undefined) => {
      await deleteSpecialDisplay(publicClientApplication, id);
      return { data: items?.data.filter((s) => s.id !== id) || [] };
    });
    closeDrawer();
  };

  const handleRowSelection = (specialDisplay: SpecialDisplay) => {
    const [specialDisplayId] = [specialDisplay.id];

    const selectedIndex = selected.indexOf(specialDisplayId);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, specialDisplayId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const selectedItemCount = () => {
    return getSelectedProductIds().length;
  };

  const isSelected = (productId: string) => {
    return selected.indexOf(productId) !== -1;
  };

  const buttonColSpan: number = headCells.length > 4 ? 6 : 4;
  const statusColSpan = 1;

  if (hidden) return <div style={{ display: "none" }} />;
  return (
    <>
      <Drawer anchor="right" open={drawerOpen} onClose={() => closeDrawer()}>
        <Box
          mt="8rem"
          px={{ xs: 4, sm: 4 }}
          py={{ xs: 0, sm: 2 }}
          minWidth={{ xs: "100vw", sm: "50vw", md: "50vw" }}
          maxWidth={{ xs: "100vw", sm: "75vw", md: "60vw" }}
        >
          {editItem ? (
            <EditSpecialDisplayDrawer
              item={editItem}
              onCancel={closeDrawer}
              onSave={handleEdit}
              onDelete={handleDelete}
            />
          ) : (
            <CreateSpecialDisplayDrawer onCancel={closeDrawer} onSave={handleCreate} />
          )}
        </Box>
      </Drawer>
      <TableToolbar hasSelections={false} actionsComponent={<div />} filtersComponent={<FilterToolbar />} />
      <Paper className={classes.paper}>
        <TableContainer>
          <div style={{ position: "relative" }}>
            {loading && items.length > 0 && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(255,255,255,0.7)",
                  zIndex: 1000,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={36} />
              </div>
            )}
            <Table size="small">
              <TableHead
                headCells={headCells}
                order={orderDirection}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                isAllSelected={isAllSelected}
                onSelectAllClick={handleSelectAll}
              />
              <TableBody>
                {items.map((sd: SpecialDisplay, index: number) => {
                  const packageType: PackageType = sd.product
                    ? (sd.product.packageTypeId[0] as PackageType)
                    : PackageType.OTHER;

                  const isPastTheme = isAfter(new Date(), new Date(sd.endDate));
                  const isItemSelected = isSelected(sd.id);
                  const displayTypeName =
                    sd.specialDisplayType?.id === SpecialDisplayTypeId.DOLLY ||
                    sd.specialDisplayType?.id === SpecialDisplayTypeId.MINIDOLLY ||
                    sd.specialDisplayType?.id === SpecialDisplayTypeId.COLD_CABINET
                      ? `${sd.specialDisplayType?.name} ${sd.displaySubType ? "/ " + sd.displaySubType.name : ""}`
                      : sd.specialDisplayType?.name;

                  return (
                    <TableRow hover tabIndex={-1} key={sd.id}>
                      <TableCell padding="checkbox">
                        <Checkbox color="primary" checked={isItemSelected} onClick={() => handleRowSelection(sd)} />
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip placement="bottom-start" title={`Myymälä: ${sd.store.name}`} enterDelay={700}>
                          <Box component="span">{sd.product.id}</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip
                          placement="bottom-start"
                          title={`Toimittaja: ${sd.product.agentId} ${sd.product.agentName}`}
                          enterDelay={700}
                        >
                          <Box component="span">{sd.product.name}</Box>
                        </Tooltip>
                      </TableCell>
                      <Hidden mdDown>
                        <TableCell align="left">{upperFirst(sd.product.productGroupName[0])}</TableCell>
                      </Hidden>
                      <Hidden mdDown>
                        <TableCell align="right">{formatEur(sd.product.price)}</TableCell>
                      </Hidden>
                      <Hidden mdDown>
                        <TableCell align="left">{sd.product.basket}</TableCell>
                      </Hidden>
                      <Hidden mdDown>
                        <TableCell align="left">{sd.product.countryName}</TableCell>
                      </Hidden>
                      <Hidden lgDown>
                        <TableCell align="left">{sd.product.agentName}</TableCell>
                      </Hidden>
                      <Hidden smDown>
                        <TableCell align="center">
                          <Tooltip placement="bottom-start" title={`${sd.product.distributor}`} enterDelay={700}>
                            <Box component="span">{sd.product.distributorId}</Box>
                          </Tooltip>
                        </TableCell>
                      </Hidden>
                      <Hidden smDown>
                        <TableCell align="left">{displayTypeName}</TableCell>
                      </Hidden>
                      <Hidden smDown>
                        <TableCell align="left">
                          {sd.amount}&nbsp;{units[packageType]?.shortName}
                        </TableCell>
                      </Hidden>
                      <Hidden smDown>
                        <TableCell component="th" scope="row" align="left">
                          {formatDatePeriod(sd.startDate, sd.endDate, sd.indefinitely ?? false)}
                        </TableCell>
                      </Hidden>
                      <TableCell align="right" padding="checkbox">
                        <Box display="flex" alignContent="center" justifyContent="flex-end" className="actions">
                          <IconButton size="small" onClick={() => openDrawerForEdit(sd)}>
                            {isPastTheme && (sd.indefinitely ?? false) === false ? (
                              <InfoIcon fontSize="medium" />
                            ) : (
                              <EditIcon fontSize="medium" />
                            )}
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {loading && items.length === 0 && (
                  <TableRow style={{ minHeight: "3.5rem", height: "3.5rem" }}>
                    <TableCell colSpan={headCells.length + 2} align="center" valign="middle">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {!loading && items.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={headCells.length + 2} align="center" valign="middle">
                      <Box
                        style={{
                          minHeight: "3.5rem",
                          height: "3.5rem",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        {!storeId ? "Valitse myymälä" : "Ei tuloksia"}
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                {!loading && (
                  <TableRow style={{ minHeight: "3.5rem", height: "3.5rem" }}>
                    <TableCell colSpan={buttonColSpan} align="left">
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={openDrawerForCreate}
                        disabled={isAddProductDisabled}
                      >
                        Lisää tuote esillepanoon
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<PrintOutlineIcon />}
                        onClick={printSelectedProducts}
                        disabled={isPrintDisabled}
                      >
                        {isPrintDisabled ? "Tulosta" : `Tulosta (${selectedItemCount()})`}
                      </Button>
                    </TableCell>
                    <Hidden mdDown>
                      <TableCell colSpan={4} align="left" />
                    </Hidden>
                    <Hidden lgDown>
                      <TableCell align="left" />
                    </Hidden>
                    <Hidden smDown>
                      <TableCell colSpan={statusColSpan} align="left" style={{ whiteSpace: "nowrap" }}>
                        {displayStatuses
                          .slice(0, 2)
                          .map((option) => `${option.id} - ${option.name}`)
                          .join(" ")}
                        <br />
                        {displayStatuses
                          .slice(2)
                          .map((option) => `${option.id} - ${option.name}`)
                          .join(" ")}
                      </TableCell>
                      <TableCell />
                    </Hidden>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 40]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={items.length === 0 ? 0 : page}
          onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setRowsPerPage(parseInt(event.target.value));
            setPage(0);
          }}
          onPageChange={(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage)}
        />
      </Paper>
    </>
  );
};
