import useSWR from "swr";
import { getApiUrl } from "../utils/request";
import fetch from "../utils/fetch";
import { ApiResponse } from "../utils/response";
import { Period, StoreCampaign, ThemeCampaign, ThemeResponse } from "../api/themes";

export function useThemes(
  year?: number,
  storeId?: number,
  startDate?: Date,
  endDate?: Date,
): {
  year?: number;
  periods: Period[];
  campaigns: ThemeCampaign[];
  storeCampaigns: StoreCampaign[];
  isLoading: boolean;
  isError: boolean;
} {
  const { data, error } = useSWR<ApiResponse<ThemeResponse>>(
    year && startDate && endDate
      ? getApiUrl("/v1/themes", {
          year,
          storeId,
          startDate: startDate?.toISOString().substring(0, 10),
          endDate: endDate?.toISOString().substring(0, 10),
        })
      : null,
    fetch,
  );

  return {
    year,
    periods: data?.data.periods || [],
    campaigns: data?.data.campaigns || [],
    storeCampaigns: data?.data.storeCampaigns || [],
    isLoading: !error && !data,
    isError: error,
  };
}
