/* eslint-disable no-restricted-globals */
import React from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import { IconButton } from "@material-ui/core";

export const ForceRefreshButton = (): JSX.Element => (
  <IconButton
    size="small"
    color="inherit"
    onClick={() => {
      location.href = window.location.href;
    }}
  >
    <RefreshIcon />
  </IconButton>
);
