import React from "react";
import {
  CircularProgress,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useThemes } from "../../hooks/useThemes";
import { FilterToolbar } from "./FilterToolbar";
import { StoreCampaignRow } from "./StoreCampaignRow";
import { ThemeCampaignRow } from "./ThemeCampaignRow";
import { useSavedStore } from "../../hooks/useSavedStore";
import { useThemePeriods } from "../../hooks/useThemePeriods";
import { useSavedYear } from "../../hooks/useSavedYear";
import { useSavedPeriod } from "../../hooks/useSavedPeriod";
import startOfYear from "date-fns/startOfYear";
import endOfYear from "date-fns/endOfYear";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
  }),
);

const ThemesTable = (): JSX.Element => {
  const classes = useStyles();
  const smallScreen = useMediaQuery("(max-width:768px)");
  const { storeId } = useSavedStore();
  const { year } = useSavedYear();
  const { periodId } = useSavedPeriod();
  const { periodsMap } = useThemePeriods();
  const period = periodId ? periodsMap.get(periodId) : null;

  const { campaigns, storeCampaigns, isLoading } = useThemes(
    year || undefined,
    storeId || undefined,
    period?.startDate || startOfYear(new Date(year, 0)),
    period?.endDate || endOfYear(new Date(year, 0)),
  );

  return (
    <>
      <FilterToolbar />
      {isLoading && <CircularProgress size={24} />}

      {period && (campaigns.length > 0 || storeCampaigns.length > 0) && (
        <Paper className={classes.paper}>
          <TableContainer>
            <Table size={smallScreen ? "small" : "medium"}>
              <TableHead>
                <TableRow>
                  <TableCell size="small" padding="checkbox"></TableCell>
                  <TableCell>Nimi</TableCell>
                  <TableCell>Päivämäärä</TableCell>
                  <TableCell size="small" padding="checkbox"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {storeId ? (
                  campaigns?.map((c, i) => <ThemeCampaignRow key={i} campaign={c} />)
                ) : (
                  <TableRow>
                    <TableCell size="small" padding="checkbox"></TableCell>
                    <TableCell colSpan={3} padding="normal" align="center">
                      <Typography variant="body1">Valitse myymälä</Typography>
                    </TableCell>
                  </TableRow>
                )}
                {storeId && storeCampaigns?.length > 0 && (
                  <TableRow style={{ backgroundColor: "#eee" }}>
                    <TableCell size="small" padding="checkbox"></TableCell>
                    <TableCell colSpan={3} padding="normal">
                      <Typography variant="body1" style={{ textTransform: "uppercase" }}>
                        Vapaavalintaiset teemat
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {storeId ? storeCampaigns?.map((c, i) => <StoreCampaignRow key={i} campaign={c} />) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </>
  );
};

export default ThemesTable;
