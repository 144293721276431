import useSWR from "swr";
import { getApiUrl } from "../utils/request";
import { Store } from "../api/stores";
import fetch from "../utils/fetch";
import { ApiResponse } from "../utils/response";

const collator = new Intl.Collator(["fi", "sv"]);

export function useAllStores(): {
  stores: Store[];
  storesMap: Map<number, Store>;
  isLoading: boolean;
  isError: boolean;
} {
  const { data, error } = useSWR<ApiResponse<Store[]>>(getApiUrl("/v1/stores"), fetch);

  const stores: Store[] = data ? data.data : [];
  if (!error && stores.length > 0) {
    stores.sort((a: Store, b: Store) => collator.compare(a.name, b.name));
  }

  return {
    stores: stores,
    storesMap: new Map<number, Store>(stores.map((s) => [s.id, s])),
    isLoading: !error && !data,
    isError: error,
  };
}
